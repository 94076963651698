import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import { BrowserRouter } from "react-router-dom";
import UserStatusProvider from './context/UserStatusProvider';
import ModalStatusProvider from './context/ModalStatusProvider';
import { Provider } from 'react-redux';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ModalStatusProvider>
      <UserStatusProvider>
        {/* <BrowserRouter> */}
          <App />
        {/* </BrowserRouter> */}
      </UserStatusProvider>
    </ModalStatusProvider>
  </Provider>
);
