export const catalogNavigate = (
  navigate,
  { filters, page, perPage, search, sort, minPrice, maxPrice }
) => {
  let path = "?page=" + page + "&perPage=" + perPage;
  if (search !== "") {
    path += "&search=" + search;
  }
  path += "&gt=" + minPrice;
  path += "&lt=" + maxPrice;
  if (sort !== "none") {
    path += "&sort=" + sort;
  }
  let filtersStr = "";
  if (filters.length !== 0) {
    for (const item of filters) {
      if (filtersStr === "") {
        filtersStr = filtersStr + item;
      } else {
        filtersStr = filtersStr + "|" + item;
      }
    }
    path += "&filters=" + filtersStr;
  }
  navigate(path);
};
