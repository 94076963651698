import { useState } from "react";
import {
  List,
  TextField,
  ReferenceField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  EmailField,
  BooleanField,
  useNotify,
  useRefresh,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";
import { useSelector } from "react-redux";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
  <ReferenceInput
    source="staff_member"
    label="Менеджер"
    reference="staff/get_staff"
    key="staff-filter"
  />,
];

export const PartnersList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="full_name" />
      <TextField source="adress" />
      <TextField source="phone" />
      <EmailField source="email" />
      <TextField source="EDRPOU" />
      <TextField source="division" />
      <TextField source="IPN" />
      <ReferenceField
        source="staff_member"
        reference="staff/get_staff"
        label="Співробітник"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="price_scheme" />
      <TextField source="ex_info" />
      <TextField source="dog_v_osobi" />
      <TextField source="dog_na_pidstavi" />
      <TextField source="dog_kerivnik" />
      <TextField source="account" />
      <TextField source="account_sec" />
      <TextField source="bank" />
      <TextField source="MFO" />
      <BooleanField source="kazna" />
      <TextField source="code1C" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const PartnersShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Перегляд картки користувача"
    >
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="full_name" />
          <TextField source="adress" />
          <TextField source="phone" />
          <EmailField source="email" />
          <TextField source="EDRPOU" />
          <TextField source="division" />
          <TextField source="IPN" />
          <ReferenceField
            source="staff_member"
            reference="staff/get_staff"
            label="Співробітник"
            emptyText="-"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="price_scheme" />
          <TextField source="ex_info" />
          <TextField source="dog_v_osobi" />
          <TextField source="dog_na_pidstavi" />
          <TextField source="dog_kerivnik" />
          <TextField source="account" />
          <TextField source="account_sec" />
          <TextField source="bank" />
          <TextField source="MFO" />
          <BooleanField source="kazna" />
          <TextField source="code1C" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => {
  const priceSchemeList = useSelector((state) => state.user.priceSchemeList);

  // Формуємо список опцій для SelectInput
  const selectList = priceSchemeList.map((item) => ({
    id: item.code, // Унікальний ідентифікатор
    name: item.code, // Текст, який буде відображатися
  }));

  // let selectList = [];
  // for (let item of priceSchemeList) {
  //   selectList.push(item["code"]);
  // }

  return (
    <>
      <TextInput source="name" />
      <TextInput source="full_name" />
      <TextInput source="adress" />
      <TextInput source="phone" />
      <TextInput source="email" />
      <TextInput source="EDRPOU" />
      <TextInput source="division" />
      <TextInput source="IPN" />
      <ReferenceInput source="staff_member" reference="staff/get_staff">
        <AutocompleteInput optionText="name" label="Менеджер" />
      </ReferenceInput>
      <TextInput source="ex_info" multiline rows={5} />
      <TextInput source="dog_v_osobi" />
      <TextInput source="dog_na_pidstavi" />
      <TextInput source="dog_kerivnik" />
      <TextInput source="account" />
      <TextInput source="account_sec" />
      <TextInput source="bank" />
      <TextInput source="MFO" />
      <BooleanInput source="kazna" />
      {/* <TextInput source="price_scheme" /> */}
      <SelectInput
        source="price_scheme"
        label="Схема ціноутворення"
        choices={selectList} // Передаємо список опцій
      />
      <TextInput
        source="code1C"
        slotProps={{
          input: {
            disabled: true,
          },
        }}
      />
    </>
  );
};

export const PartnersEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Редагування картки клієнта"
    >
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const PartnersCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Клієнта успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки клієнта"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
