import { useContext, useEffect, useState } from "react";
import RootGroup from "./RootGroupPortal";
import { Menu } from "react-admin";
import apiaxios from "../../../common/apiaxios";
import userStatusContext from "../../../context/userStatusContext";

const CatalogMenuPortal = () => {
  const [list, setList] = useState([]);
  const { selectVendor } = useContext(userStatusContext);
  // const { setPage, setMinPrice, setMaxPrice } = useContext(userStatusContext);
  // const perPage = JSON.parse(localStorage.getItem("productsPerPage"))
  //   ? JSON.parse(localStorage.getItem("productsPerPage"))
  //   : 20;
  useEffect(() => {
    apiaxios
      .get("/catalog/get_category_for_catalog_menu/standart", {
        params: {
          vendor: selectVendor,
        },
      })
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectVendor]);
  // const [list, setList] = useState([]);
  // useEffect(() => {
  //   apiaxios
  //     .get("/catalog/get_category_for_catalog_menu/standart", {
  //       params: {
  //         vendor: "ALL",
  //       },
  //     })
  //     .then((res) => {
  //       setList(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <>
      {list.length !== 0 && (
        <Menu width={"100%"}>
          {list.map((item) => (
            <div key={item.categoryID}>
              <RootGroup item={item} />
            </div>
          ))}
        </Menu>
      )}
    </>
  );
};

export default CatalogMenuPortal;
