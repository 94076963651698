import { useState, useContext, useEffect, useRef } from "react";
import userStatusContext from "../../context/userStatusContext";
import css from "./CategoriesFilters.module.css";
import { IconContext } from "react-icons";
import { CgChevronDown, CgChevronRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { createDebouncedCatalogNavigate } from "../../common/createDebouncedCatalogNavigate";

const CategoriesFiltersPrice = () => {
  const {
    sortTypePrice,
    search,
    filters,
    perPage,
    setPage,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
  } = useContext(userStatusContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(
    minPrice !== 0 || maxPrice !== 1000000 ? true : false
  );

  // Використовуйте useRef для зберігання debouncedCatalogNavigate
  const debouncedCatalogNavigateRef = useRef();

  // Використовуйте useEffect для створення дебаунс-функції
  useEffect(() => {
    debouncedCatalogNavigateRef.current = createDebouncedCatalogNavigate(
      navigate,
      filters,
      perPage,
      sortTypePrice,
      search,
      setPage
    );

    return () => {
      debouncedCatalogNavigateRef.current.cancel();
    };
  }, [navigate, filters, perPage, sortTypePrice, search, setPage]);

  return (
    <>
      <div className={css["filter-block-group"]}>
        <div
          className={css["filter-block-button"]}
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <IconContext.Provider value={{ size: "1em" }}>
              <div>
                <CgChevronDown />
              </div>
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ size: "1em" }}>
              <div>
                <CgChevronRight />
              </div>
            </IconContext.Provider>
          )}
        </div>
        <div
          className={css["filter-block-group-title"]}
          onClick={() => {
            setShow(!show);
          }}
        >
          Фільтр за ціною
        </div>
        {show && (
          <div className={css["filter-price-block"]}>
            <div className={css["filter-price-group"]}>
              <label>від</label>
              <input
                className={css["filter-price-input"]}
                name="from"
                value={minPrice === 0 ? "" : minPrice}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setMinPrice(value);
                  debouncedCatalogNavigateRef.current(value, maxPrice);
                }}
              />
            </div>
            <div className={css["filter-price-group"]}>
              <label>до</label>
              <input
                className={css["filter-price-input"]}
                name="to"
                value={maxPrice === 1000000 ? "" : maxPrice}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setMaxPrice(value);
                  debouncedCatalogNavigateRef.current(minPrice, value);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoriesFiltersPrice;
