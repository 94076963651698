import { useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import css from "../components/ProductsList/ProductsList.module.css";
import ProductListPath from "../components/ProductsList/ProductListPath";
import CategoriesFilters from "../components/CategoriesFilters/CategoriesFilters";
import CategoriesFiltersSearch from "../components/CategoriesFilters/CategoriesFiltersSearch";
import ProductsList from "../components/ProductsList/ProductsList";

const ProductsListPage = ({ type }) => {
  const [searchParams] = useSearchParams();
  const { categoryID } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <>
      <div className={css["product-list-container"]}>
        {(categoryID || searchParams.get("search")) &&
          categoryID !== "0000" && <ProductListPath categoryID={categoryID} />}
        <div className={css["product-list-filters-block"]}>
          {!location.pathname.startsWith("/admin_portal/") && (
            <>
              {categoryID && categoryID !== "0000" && (
                <div className={css["filters-block"]}>
                  <CategoriesFilters categoryID={categoryID} />
                </div>
              )}
              {(searchParams.get("search") || type === "pricelist") && (
                <div className={css["filters-block"]}>
                  <CategoriesFiltersSearch />
                </div>
              )}
            </>
          )}
          <ProductsList setIsOpen={setIsOpen} type={type} />
        </div>
      </div>
      {modalIsOpen && (
        <div className="backdrop-modal">
          <div className={css["modal-filters-container"]}>
            <div className={css["modal-filters-header"]}>
              <div
                className={css["modal-filters-header-btn"]}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Результат
              </div>
              <div
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <svg width="20" height="20">
                  <use xlinkHref="#svg-icon__close" />
                </svg>
              </div>
            </div>
            {categoryID && <CategoriesFilters categoryID={categoryID} />}
            {searchParams.get("search") && <CategoriesFiltersSearch />}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductsListPage;
