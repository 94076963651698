import { IconContext } from "react-icons";
import { CgRadioCheck, CgRadioChecked } from "react-icons/cg";
import css from "./Basket.module.css";
import NovaPoshta from "../NovaPoshta/NovaPoshta";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectBasketAmount,
  selectBasketAmountIn,
  selectBasketCount,
  selectBasketClient,
  selectBasketComent,
  selectBasketConfirm,
  selectBasketDelivery,
  selectBasketFirm,
  selectBasketItems,
  selectBasketManager,
  selectBasketOrderID,
  selectBasketPriceScheme,
} from "../../redux/basketSelectors";
import { changeBasketDelivery } from "../../redux/basketSlice";
import { saveBasket } from "./saveBasket";
import BasketDeliveryFormObserver from "./BasketDeliveryFormObserver";

const BasketDelivery = ({ saveOrder, setDeliveryShow, modalClose }) => {
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user.status);
  const userEmail = useSelector((state) => state.user.email);
  const basket = useSelector(selectBasketItems);
  const basketDelivery = useSelector(selectBasketDelivery);
  const basketComent = useSelector(selectBasketComent);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basketDate = useSelector((state) => state.basket.updateDate);
  const basketCount = useSelector(selectBasketCount);
  const basketAmount = useSelector(selectBasketAmount);
  const basketAmountIn = useSelector(selectBasketAmountIn);
  const basketClient = useSelector(selectBasketClient);
  const basketFirm = useSelector(selectBasketFirm);
  const basketManager = useSelector(selectBasketManager);
  const basketConfirm = useSelector(selectBasketConfirm);
  const basketPriceScheme = useSelector(selectBasketPriceScheme);
  const basketPlanDate = useSelector((state) => state.basket.planDate);

  // Схема валідації з Yup
  const DeliverySchema = Yup.object()
    .shape({
      surname: Yup.string().required("Будь ласка, введіть прізвище"),
      firstname: Yup.string().required("Будь ласка, введіть ім'я"),
      telephone: Yup.string()
        .transform((value) => {
          const digits = value.replace(/\D/g, "");
          return digits.length === 10
            ? `(${digits.slice(0, 3)})${digits.slice(3)}`
            : value;
        })
        .matches(
          /^\(\d{3}\)\d{7}$/,
          "Невірний формат телефону, має бути (XXX)XXXXXXX"
        )
        .required("Вкажіть номер телефону"),
      email: Yup.string()
        .email("Невірний формат email")
        .required("Email обов'язковий"),
      regionName: Yup.string()
        .transform((value) => (value === "Виберіть область" ? "" : value))
        .when("deliveryType", (deliveryType, schema) =>
          deliveryType === "np" || deliveryType === "np_adr"
            ? schema.required("Виберіть область")
            : schema
        ),
      cityName: Yup.string()
        .transform((value) => (value === "Виберіть місто" ? "" : value))
        .when("deliveryType", (deliveryType, schema) =>
          deliveryType === "np" || deliveryType === "np_adr"
            ? schema.required("Виберіть місто")
            : schema
        ),
      pointName: Yup.string()
        .transform((value) => (value === "Виберіть відділення" ? "" : value))
        .when("deliveryType", (deliveryType, schema) =>
          deliveryType === "np"
            ? schema.required("Виберіть відділення")
            : schema
        ),
      adressDelivery: Yup.string().when(
        "deliveryType",
        (deliveryType, schema) =>
          deliveryType === "np_adr"
            ? schema.required("Введіть адресу доставки")
            : schema
      ),
      deliveryType: Yup.string().required(),
    })
    .test(
      "np-required-fields",
      "Заповніть будь ласка всі реквізити для доставки Новою поштою!",
      function (values) {
        const {
          deliveryType,
          regionName,
          cityName,
          pointName,
          adressDelivery,
        } = values;
        if (deliveryType === "np") {
          if (!regionName) {
            return this.createError({
              path: "regionName",
              message: "Виберіть область",
            });
          }
          if (!cityName) {
            return this.createError({
              path: "cityName",
              message: "Виберіть місто",
            });
          }
          if (!pointName) {
            return this.createError({
              path: "pointName",
              message: "Виберіть відділення",
            });
          }
        }

        if (deliveryType === "np_adr") {
          if (!regionName) {
            return this.createError({
              path: "regionName",
              message: "Виберіть область",
            });
          }
          if (!cityName) {
            return this.createError({
              path: "cityName",
              message: "Виберіть місто",
            });
          }
          if (!adressDelivery) {
            return this.createError({
              path: "adressDelivery",
              message: "Введіть адресу доставки",
            });
          }
        }

        return true;
      }
    );

  // Початкові значення форми
  const initialValues = {
    surname: basketDelivery["surname"] || "",
    firstname: basketDelivery["firstname"] || "",
    telephone: basketDelivery["telephone"] || "",
    email:
      basketDelivery["email"] ||
      ("guest" !== userStatus ? userEmail : "") ||
      "",
    deliveryType: basketDelivery["deliveryType"] || "office",
    regionRef: basketDelivery["regionRef"] || "",
    regionName: basketDelivery["regionName"] || "",
    cityRef: basketDelivery["cityRef"] || "",
    cityName: basketDelivery["cityName"] || "",
    pointRef: basketDelivery["pointRef"] || "",
    pointName: basketDelivery["pointName"] || "",
    adressDelivery: basketDelivery["adressDelivery"] || "",
    comment: basketDelivery["comment"] || "",
  };

  const onSubmit = async (values, { setSubmitting }) => {
    // Оновлюємо дані доставки в сторах
    dispatch(changeBasketDelivery({ ...values }));

    // Зберігаємо кошик
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      basketClient,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      values
    );
    // Виклик збереження замовлення
    if (basket.length === 0) {
      toast.error("Кошик порожній!");
    } else {
      saveOrder();
    }
    setSubmitting(false);
  };

  return (
    <div className={css["delivery-container"]}>
      <Formik
        initialValues={initialValues}
        validationSchema={DeliverySchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className="form-order">
            <BasketDeliveryFormObserver />
            <div className={css["delivery-block"]}>
              <div>
                <div
                  id="office"
                  className={css["delivery-item"]}
                  onClick={() => setFieldValue("deliveryType", "office")}
                >
                  <IconContext.Provider value={{ size: "1em" }}>
                    <div>
                      {values.deliveryType === "office" ? (
                        <CgRadioChecked />
                      ) : (
                        <CgRadioCheck />
                      )}
                    </div>
                  </IconContext.Provider>
                  <div className={css["delivery-label"]}>
                    Самовивіз з магазину м.Суми, вул.Привокзальна, 15
                  </div>
                </div>
                <div
                  id="np"
                  className={css["delivery-item"]}
                  onClick={() => setFieldValue("deliveryType", "np")}
                >
                  <IconContext.Provider value={{ size: "1em" }}>
                    <div>
                      {values.deliveryType === "np" ? (
                        <CgRadioChecked />
                      ) : (
                        <CgRadioCheck />
                      )}
                    </div>
                  </IconContext.Provider>
                  <div className={css["delivery-label"]}>
                    Новою поштою на відділення
                  </div>
                </div>
                <div
                  id="np_adr"
                  className={css["delivery-item"]}
                  onClick={() => setFieldValue("deliveryType", "np_adr")}
                >
                  <IconContext.Provider value={{ size: "1em" }}>
                    <div>
                      {values.deliveryType === "np_adr" ? (
                        <CgRadioChecked />
                      ) : (
                        <CgRadioCheck />
                      )}
                    </div>
                  </IconContext.Provider>
                  <div className={css["delivery-label"]}>
                    Новою поштою кур'єром
                  </div>
                </div>
                <div>
                  {(values.deliveryType === "np" ||
                    values.deliveryType === "np_adr") && (
                    <>
                      <NovaPoshta
                        data={values.regionRef}
                        setData={(val) => {
                          setFieldValue("regionRef", val);
                        }}
                        dataName={values.regionName}
                        setDataName={(val) => setFieldValue("regionName", val)}
                        type={"region"}
                        info={""}
                      />
                      <ErrorMessage name="regionName">
                        {() => (
                          <div className={css["np-error"]}>
                            {"Обов'язкове поле!"}
                          </div>
                        )}
                      </ErrorMessage>
                    </>
                  )}
                  {(values.deliveryType === "np" ||
                    values.deliveryType === "np_adr") &&
                    values.regionRef !== "" && (
                      <>
                        <NovaPoshta
                          data={values.cityRef}
                          setData={(val) => {
                            setFieldValue("cityRef", val);
                          }}
                          dataName={values.cityName}
                          setDataName={(val) => setFieldValue("cityName", val)}
                          type={"city"}
                          info={values.regionRef}
                        />
                        <ErrorMessage name="cityName">
                          {() => (
                            <div className={css["np-error"]}>
                              {"Обов'язкове поле!"}
                            </div>
                          )}
                        </ErrorMessage>
                      </>
                    )}
                  {values.deliveryType === "np" &&
                    values.regionRef !== "" &&
                    values.cityRef !== "" && (
                      <>
                        <NovaPoshta
                          data={values.pointRef}
                          setData={(val) => {
                            setFieldValue("pointRef", val);
                          }}
                          dataName={values.pointName}
                          setDataName={(val) => setFieldValue("pointName", val)}
                          type={"point"}
                          info={values.cityRef}
                        />
                        <ErrorMessage name="pointName">
                          {() => (
                            <div className={css["np-error"]}>
                              {"Обов'язкове поле!"}
                            </div>
                          )}
                        </ErrorMessage>
                      </>
                    )}
                  {values.deliveryType === "np_adr" &&
                    values.regionRef !== "" &&
                    values.cityRef !== "" && (
                      <>
                        <NovaPoshta
                          data={values.adressDelivery}
                          setData={(val) => {
                            setFieldValue("adressDelivery", val);
                          }}
                          dataName={values.adressDelivery}
                          setDataName={(val) =>
                            setFieldValue("adressDelivery", val)
                          }
                          type={"adress"}
                          info={values.pointRef}
                        />
                        <ErrorMessage name="adressDelivery">
                          {() => (
                            <div className={css["np-error"]}>
                              {"Обов'язкове поле!"}
                            </div>
                          )}
                        </ErrorMessage>
                      </>
                    )}
                </div>
              </div>
              {/* {(userStatus === "guest" || values.deliveryType !== "office") && ( */}
              <div>
                <div className={css["form-group"]}>
                  <label>Прізвище:</label>
                  <Field
                    name="surname"
                    className={css["form-control"]}
                    type="text"
                  />
                  <ErrorMessage
                    name="surname"
                    component="div"
                    className={css["np-error"]}
                  />
                </div>
                <div className={css["form-group"]}>
                  <label>Ім'я:</label>
                  <Field
                    name="firstname"
                    className={css["form-control"]}
                    type="text"
                  />
                  <ErrorMessage
                    name="firstname"
                    component="div"
                    className={css["np-error"]}
                  />
                </div>
                <div className={css["form-group"]}>
                  <label>Номер телефону:</label>
                  <InputMask
                    className={css["form-control"]}
                    name="telephone"
                    mask="(999)9999999"
                    value={values.telephone}
                    onChange={(e) => setFieldValue("telephone", e.target.value)}
                  />
                  <ErrorMessage
                    name="telephone"
                    component="div"
                    className={css["np-error"]}
                  />
                </div>
                {userStatus === "guest" && (
                  <div className={css["form-group"]}>
                    <label>Електронна пошта:</label>
                    <Field
                      name="email"
                      className={css["form-control"]}
                      type="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className={css["np-error"]}
                    />
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
            <div className={css["form-group"]} style={{ width: "100%" }}>
              <label>Коментар до доставки:</label>
              <Field
                name="comment"
                className={css["form-control"]}
                type="text"
              />
            </div>
            {userStatus !== "staff" && userStatus !== "super" && (
              <div style={{ marginTop: "15px", marginLeft: "5px" }}>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={css["btn"]}
                >
                  Замовити
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setDeliveryShow(false);
                    modalClose();
                  }}
                  className={css["btn"]}
                >
                  Продовжити покупки
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </div>
  );
};

export default BasketDelivery;
