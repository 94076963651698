import React, { useState, useEffect } from "react";
import {
  useUpdate,
  useNotify,
  useRefresh,
  useRecordContext,
} from "react-admin";

const EditableTextCell = ({ source }) => {
  const record = useRecordContext();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(record ? record[source] : "");
  const [update] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    if (record) {
      setValue(record[source]);
    }
  }, [record, source]);

  const handleSave = () => {
    if (record) {
      update(
        "catalog/get_brands", // змініть на вашу ресурсну назву
        { id: record.id, data: { [source]: value } },
        {
          onSuccess: () => {
            setIsEditing(false);
            notify("Значення успішно оновлено", { type: "info" });
            refresh();
          },
          onError: (error) => {
            notify(`Помилка: ${error.message}`, { type: "warning" });
          },
        }
      );
    }
  };

  if (!record) {
    return null;
  }

  return isEditing ? (
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={handleSave}
      autoFocus
    />
  ) : (
    <span onClick={() => setIsEditing(true)}>{record[source]}</span>
  );
};

export default EditableTextCell;
