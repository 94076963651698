import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        status: "",
        name: "",
        surname: "",
        email: "",
        telephone: "",
        price: "",
        defaultClient: {"id": 0, "name": ""},
        defaultWarehouseClient: {"id": 0, "name": ""},
        defaultWarehouse: {"id": 0, "name": ""},
        defaultFirm: {"id": 0, "name": ""},
        staffMember: { "id": 0, "name": "" },
        priceSchemeList: [],
        favoriteCount: 0,
        compareCount: 0,
    },
    reducers: {
        changeUserStatus(state, action) {
            state.status = action.payload;
        },
        changeUserName(state, action) {
            state.name = action.payload;
        },
        changeUserSurname(state, action) {
            state.surname = action.payload;
        },
        changeUserEmail(state, action) {
            state.email = action.payload;
        },
        changeUserTelephone(state, action) {
            state.telephone = action.payload;
        },
        changeUserPrice(state, action) {
            state.price = action.payload;
        },
        changePriceSchemeList(state, action) {
            state.priceSchemeList = action.payload;
        },
        changeUserDefaultClient(state, action) {
            state.defaultClient = action.payload;
        },
        changeUserDefaultWarehouseClient(state, action) {
            state.defaultWarehouseClient = action.payload;
        },
        changeUserDefaultWarehouse(state, action) {
            state.defaultWarehouse = action.payload;
        },
        changeUserDefaultFirm(state, action) {
            state.defaultFirm = action.payload;
        },
        changeUserStaffMember(state, action) {
            state.staffMember = action.payload;
        },
        changeUserFavoriteCount(state, action) {
            state.favoriteCount = action.payload;
        },
        changeUserCompareCount(state, action) {
            state.compareCount = action.payload;
        },
    }
})

export const userReducer = userSlice.reducer;

export const { changeUserStatus, changeUserName, changeUserSurname, changeUserEmail, changeUserTelephone, changeUserPrice, changePriceSchemeList, changeUserDefaultClient, changeUserDefaultFirm, changeUserStaffMember, changeUserFavoriteCount, changeUserCompareCount, changeUserDefaultWarehouseClient, changeUserDefaultWarehouse } = userSlice.actions;