import {
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  //   useRecordContext,
} from "react-admin";

const EditToolbar = () => {
  //   const record = useRecordContext();
  return (
    <Toolbar>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent="Видалити картку?"
        confirmColor="warning"
        // translateOptions={{ name: record?.name }}
        sx={{
          background: "white",
          marginLeft: 3,
          padding: 1,
        }}
      />
    </Toolbar>
  );
};

export default EditToolbar;
