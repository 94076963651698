import apiaxios from "../../common/apiaxios";

export const saveBasket = (
  userStatus,
  basketOrderID,
  basketDate,
  basket,
  basketCount,
  basketAmount,
  basketAmountIn,
  basketClient,
  basketWarehouse,
  basketFirm,
  basketManager,
  basketConfirm,
  basketPriceScheme,
  basketPlanDate,
  basketComent,
  basketDelivery
) => {
  if (userStatus !== "guest") {
    const newStorage = {
      basketDate: basketDate,
      basketOrderID: basketOrderID,
      items: basket,
      basketCount: basketCount,
      basketAmount: basketAmount,
      basketAmountIn: basketAmountIn,
      basketClient: basketClient,
      basketWarehouse: basketWarehouse,
      basketFirm: basketFirm,
      basketManager: basketManager,
      basketConfirm: basketConfirm,
      basketPriceScheme: basketPriceScheme,
      basketPlanDate: basketPlanDate,
      basketComent: basketComent,
      basketDelivery: basketDelivery,
    };
    apiaxios.post(
      "/orders/save_cart/",
      JSON.stringify(newStorage),
      { headers: { "Content-Type": "application/json" } },
      { withCredentials: true }
    );
  } else {
    const newStorage = {
      basketDate: basketDate,
      items: basket,
      basketCount: basketCount,
      basketAmount: basketAmount,
    };
    localStorage.setItem("basketStorage", JSON.stringify(newStorage));
  }
};
