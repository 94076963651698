import css from "./Orders.module.css";
import { Link } from "react-router-dom";
import no_photo from "../../no-photo-api.png";
import { IconContext } from "react-icons";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import { useState } from "react";
import apiaxios from "../../common/apiaxios";
import { useSelector } from "react-redux";

const OrderInfo = ({ item, statuses, statusList }) => {
  const userStatus = useSelector((state) => state.user.status);
  const [statusOnOff, setStatusOnOff] = useState(false);
  const [activeStatus, setActiveStatus] = useState(statuses[item.status]);
  const [show, setShow] = useState(false);
  const pic = [];
  let plus = 0;
  let i = 0;
  if (item.items.length <= 6) {
    for (const elem of item.items) {
      pic.push(elem);
    }
  } else {
    while (i < 5) {
      pic.push(item.items[i]);
      i = i + 1;
    }
    plus = item.items.length - 5;
  }

  const selectStatus = (e) => {
    setActiveStatus(e.currentTarget.id);
    for (let key in statuses) {
      if (statuses[key] === e.currentTarget.id) {
        apiaxios.post(
          "/orders/change_status/",
          {
            status: key,
            order: item.id,
          },
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        );
      }
    }
    setStatusOnOff(!statusOnOff);
  };

  return (
    <div className={css["order-info-container"]}>
      <div className={css["order-info-header-container"]}>
        <div
          className={css["order-info-header"]}
          onClick={() => {
            setShow(!show);
          }}
        >
          <div>
            <div>
              Замовлення № {item.id} від{" "}
              {item.date.slice(0, 19).replace("T", " ")}
            </div>
            {(userStatus === "super" || userStatus === "staff") &&
              item.name && (
                <div>
                  Клієнт: {item.name} Телефон: {item.telephone}
                </div>
              )}
          </div>
          {!show && window.innerWidth >= 1024 && (
            <div className={css["order-info-header-images"]}>
              {pic.map((row) => (
                <div key={row.id} className={css["order-info-header-image"]}>
                  {row.full_image ? (
                    <img
                      src={
                        row.full_image
                        // row.full_image.slice(0, row.full_image.length - 4) +
                        // "_small.jpg"
                      }
                      width="30px"
                      alt={row.name}
                    />
                  ) : (
                    <img src={no_photo} width="30px" alt={row.name} />
                  )}
                </div>
              ))}
              {plus > 0 && (
                <div className={css["order-info-header-plus"]}>
                  <div className={css["order-info-header-plus-text"]}>
                    +{plus}
                  </div>
                </div>
              )}
            </div>
          )}
          <div>
            <div
              className={css["order-info-header-status"]}
              onClick={(e) => {
                if (userStatus === "staff" || userStatus === "super") {
                  setStatusOnOff(!statusOnOff);
                  e.stopPropagation();
                }
              }}
            >
              {activeStatus}
              {/* <div className={css["order-status-select"]}> */}
              {statusOnOff && (
                <div className={css["status-select-container"]}>
                  {statusList.map((item) => (
                    <div
                      className={css["status-select-item"]}
                      id={item}
                      key={item}
                      onClick={selectStatus}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              )}
              {/* </div> */}
              {/* {item.status === 0 && (
                <div className={css["order-info-header-status-new"]}>
                  {statuses[item.status]}
                </div>
              )}
              {item.status === 1 && (
                <div className={css["order-info-header-status-wait"]}>
                  {statuses[item.status]}
                </div>
              )}
              {item.status === 2 && (
                <div className={css["order-info-header-status-transit"]}>
                  {statuses[item.status]}
                </div>
              )}
              {item.status === 3 && (
                <div className={css["order-info-header-status-ready"]}>
                  {statuses[item.status]}
                </div>
              )}
              {item.status === 4 && (
                <div className={css["order-info-header-status-done"]}>
                  {statuses[item.status]}
                </div>
              )}
              {item.status === 5 && (
                <div className={css["order-info-header-status-cancel"]}>
                  {statuses[item.status]}
                </div>
              )} */}
            </div>
            <div>{item.amount} ₴</div>
          </div>
        </div>
        <div
          className={css["order-info-header-btn"]}
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? (
            <IconContext.Provider value={{ size: "1em" }}>
              <div>
                <CgChevronUp />
              </div>
            </IconContext.Provider>
          ) : (
            <IconContext.Provider value={{ size: "1em" }}>
              <div>
                <CgChevronDown />
              </div>
            </IconContext.Provider>
          )}
        </div>
      </div>
      {!show && window.innerWidth < 1024 && (
        <div className={css["order-info-header-images"]}>
          {pic.map((row) => (
            <div key={row.id} className={css["order-info-header-image"]}>
              {row.full_image ? (
                <img
                  src={
                    row.full_image
                    // row.full_image.slice(0, row.full_image.length - 4) +
                    // "_small.jpg"
                  }
                  width="30px"
                  alt={row.name}
                />
              ) : (
                <img src={no_photo} width="30px" alt={row.name} />
              )}
            </div>
          ))}
          {plus > 0 && (
            <div className={css["order-info-header-plus"]}>
              <div className={css["order-info-header-plus-text"]}>+{plus}</div>
            </div>
          )}
        </div>
      )}

      {show && (
        <>
          <div>
            {item.items.map((row) => (
              <div key={row.id} className={css["order-info-row"]}>
                <div className={css["order-info-row-img-name"]}>
                  <Link to={"/product_details/" + row.id}>
                    {row.full_image ? (
                      <img
                        src={
                          row.full_image
                          // row.full_image.slice(0, row.full_image.length - 4) +
                          // "_small.jpg"
                        }
                        width="50px"
                        alt={row.name}
                      />
                    ) : (
                      <img src={no_photo} width="50px" alt={row.name} />
                    )}
                  </Link>
                  <Link
                    to={"/product_details/" + row.id}
                    className={css["order-history-row-name"]}
                  >
                    {row.name}
                  </Link>
                </div>
                <div className={css["order-info-row-price-quantity"]}>
                  <div>{row.price_im} ₴</div>
                  <div>х{row.quantity} </div>
                  <div>{row.price_im * row.quantity} ₴</div>
                </div>
              </div>
            ))}
            {item.delivery["deliveryType"] === "office" && (
              <div className={css["order-info-delivery"]}>
                Самовивіз з магазину
              </div>
            )}
            {item.delivery["deliveryType"] === "np" && (
              <div className={css["order-info-delivery"]}>
                Відправка новою поштою: {item.delivery["regionName"]},{" "}
                {item.delivery["cityName"]}, {item.delivery["pointName"]}
              </div>
            )}
            {item.delivery["deliveryType"] === "np_adr" && (
              <div className={css["order-info-delivery"]}>
                Відправка новою поштою: {item.delivery["regionName"]},{" "}
                {item.delivery["cityName"]}, {item.delivery["adressDelivery"]}
              </div>
            )}
          </div>
          {item.delivery["comment"] && (
            <div className={css["order-info-delivery"]}>
              Коментар: {item.delivery["comment"]}
            </div>
          )}
          {/* {item.coment && (
            <div className={css["order-info-delivery"]}>
              Коментар до замовлення: {item.coment}
            </div>
          )} */}
        </>
      )}
    </div>
  );
};

export default OrderInfo;
