import { IconButton, Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
import Basket from "../../../components/Basket/Basket";
import CartIcon from "../../../components/Header/CartIcon";
import { useLocation } from "react-router-dom";

export const ShoppingCartButton = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (location.pathname.startsWith("/admin_portal/orders/list")) {
      window.location.reload();
    }
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleOpen}>
        <CartIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent style={{ padding: 0 }}>
          <Basket modalClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};
