import { Link } from "react-router-dom";
import css from "./Profile.module.css";
import apiaxios from "../../common/apiaxios";
import { useSelector } from "react-redux";

const Utility = () => {
  const userStatus = useSelector((state) => state.user.status);

  const getInfo = (path) => {
    if (localStorage.getItem("access_token")) {
      apiaxios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("access_token")}`;
    }
    apiaxios
      .get(path)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(path);
  };

  return (
    <div className="content">
      {userStatus === "super" && (
        <div className={css["util-container"]}>
          <div className="page-title">Утіліти</div>
          <ul>
            <li>
              <Link
                to={process.env.REACT_APP_DJANGO_API_URL_ADM + "admin/"}
                target="_blank"
              >
                Адмін панель
              </Link>
            </li>
            <hr />
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_chron/")}
            >
              Старт завдання оновлення цін
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_chron_cards/")}
            >
              Старт завдання оновлення карток товарів
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_chron_sale_prices/")}
            >
              Старт завдання оновлення цін продажу
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_chron_filters/")}
            >
              Старт завдання оновлення фільтрів категорій
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_backup/")}
            >
              Старт завдання сворення резервної копії БД
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_prices_task/")}
            >
              Запустити зараз на виконання завдання оновлення каталогу (те що
              працює по графіку)
            </li>
            <hr />
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_by_price_brain/")}
            >
              Завантаження прайсу брейн та додавання і оновлення основних полів
              карток товару, оновлення ознаки відображення товару та груп
              товарів
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_dclink/")}
            >
              Оновлення карток товарів з DC LINK
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_mti/")}
            >
              Оновлення карток товарів з MTI
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_edg/")}
            >
              Оновлення карток товарів з EDG
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_vm/")}
            >
              Оновлення карток товарів з VM
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_lgp/")}
            >
              Оновлення карток товарів з LogicPower
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_erc/")}
            >
              Оновлення карток товарів з ERC
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_artline/")}
            >
              Оновлення карток товарів з ArtLine
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_asbis/")}
            >
              Оновлення карток товарів з ASBIS
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_catalog_neor/")}
            >
              Оновлення карток товарів з NEOR
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_inventory/")}
            >
              Завантаження залишків складу з 1С
            </li>

            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_products_available/")}
            >
              Оновлення ознаки ON/OFF (наявність) для карток товарів
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_update_sale_prices/")}
            >
              Оновлення цін продажу
            </li>
            <hr />
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_products_brain_new/")}
            >
              Оновлення карток товарів з брейну без роздрібної ціни брейна (нові
              картки)
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_products_brain/")}
            >
              Оновлення інформації в неповних картках товарів
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/add_products_brain_by_articul/")}
            >
              Додавання карток з брейн запитом на API за артикулом
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() =>
                getInfo("/catalog/update_products_brain_without_image/")
              }
            >
              Оновлення інформації в картках товарів без зображення
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() =>
                getInfo("/catalog/update_products_brain_without_brand/")
              }
            >
              Оновлення інформації в картках товарів без бренду через brain
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() =>
                getInfo("/catalog/update_products_brain_without_code/")
              }
            >
              Оновлення інформації в картках товарів без кодів brain
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_products_from_mti/")}
            >
              Оновлення інформації в картках товарів у картках з МТІ
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_categories_on_off/")}
            >
              Оновлення статусу категорій on/off, в залежності від наявності
              карток в категрорії
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_import_brands/")}
            >
              Оновлення довідника виробників з брейну
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/start_make_catalog/")}
            >
              Створення каталогів
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() =>
                getInfo("/catalog/start_make_category_filters_json/")
              }
            >
              Створення фільтрів категорій у БД
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/update_card_xit/")}
            >
              Оновлення ХІТ
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/full_update_products_brain/")}
            >
              Повне оновлення інформації в картках товарів
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/get_duplicate/")}
            >
              Перевірка та видалення дублів карток
            </li>
            <hr />
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/import_dict_from_1c/")}
            >
              Імпорт довідників з 1С
            </li>
            <hr />
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/fill_brand_for_old_card/")}
            >
              Заповнення бренду у картках, де незаповнений
            </li>
            <li
              className={css["cursor-link"]}
              onClick={() => getInfo("/catalog/onetime_func/")}
            >
              Разова рандомна обробка (просто так не тицять)
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Utility;
