import React from "react";
import { Typography, Box } from "@mui/material";
import { TextField } from "react-admin";

const TextFieldInline = ({ source, label, emptyText = "-" }) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography
        variant="caption"
        sx={{ mr: 1, ml: 1, fontWeight: "bold", fontSize: "0.8rem" }}
      >
        {label}
      </Typography>
      <TextField source={source} emptyText={emptyText} />
    </Box>
  );
};

export default TextFieldInline;
