import { useState } from "react";
import { IconButton, ListItemText, MenuItem } from "@mui/material";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import Collapse from "@mui/material/Collapse";
import GroupItem from "./GroupItemPortal";

const GroupMenuPortal = ({ item }) => {
  const [openGroup, setOpenGroup] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => setOpenGroup(!openGroup)}
        sx={{ p: 0.5, ml: 1, mr: 0.5 }}
      >
        <ListItemText
          primary={item.name}
          slotProps={{
            primary: {
              sx: {
                whiteSpace: "normal", // Дозволяє тексту переноситися
                wordWrap: "break-word", // Розбиває слова, якщо вони занадто довгі
                fontSize: 13, // (за потребою) налаштування розміру шрифту
              },
            },
          }}
        />
        <IconButton sx={{ p: 0, ml: 1, mr: 0.5 }}>
          {openGroup ? (
            <RemoveTwoToneIcon style={{ fontSize: "15px" }} />
          ) : (
            <AddTwoToneIcon style={{ fontSize: "15px" }} />
          )}
        </IconButton>
      </MenuItem>
      <Collapse in={openGroup} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
        {item.items.map((elem) => (
          <div key={elem.categoryID}>
            <GroupItem item={elem} />
          </div>
        ))}
      </Collapse>
    </>
  );
};

export default GroupMenuPortal;
