import apiaxios from "../../common/apiaxios";
import { changeUserCompareCount, changeUserEmail, changeUserPrice, changeUserFavoriteCount, changeUserName, changeUserStatus, changeUserSurname, changeUserDefaultClient, changeUserDefaultFirm, changeUserStaffMember, changeUserTelephone, changeUserDefaultWarehouseClient, changeUserDefaultWarehouse, changePriceSchemeList } from "../../redux/userSlice";
import { changeBasketAmount, changeBasketAmountIn, changeBasketClient, changeBasketWarehouse, changeBasketComent, changeBasketConfirm, changeBasketCount, changeBasketDelivery, changeBasketFirm, changeBasketItems, changeBasketManager, changeBasketOrderID, changeBasketPlanDate, changeBasketPriceScheme, changeBasketUpdateDate } from "../../redux/basketSlice";

export const getStartUserData = async (dispatch) => {
    const today = Date().slice(0, 15);
    const basketStorage = JSON.parse(localStorage.getItem("basketStorage"));
        if (localStorage.getItem("access_token")) {
            try {
                const result = await apiaxios.get("/users/get_status/");
                const data = result.data;

                // Оновлення даних користувача
                dispatch(changeUserStatus(data.status));
                localStorage.setItem("status", data.status);
                dispatch(changeUserCompareCount(data.compareCount));
                dispatch(changeUserFavoriteCount(data.favoriteCount));
                dispatch(changeUserName(data.info.first_name));
                dispatch(changeUserSurname(data.info.last_name));
                dispatch(changeUserTelephone(data.info.telephone));
                dispatch(changeUserEmail(data.info.email));
                dispatch(changeUserPrice(data.info.price));
                dispatch(changePriceSchemeList(data.info.price_scheme_list));
                dispatch(changeUserDefaultClient(data.info.default_client));
                dispatch(changeUserDefaultWarehouseClient(data.info.default_warehouse_client));
                dispatch(changeUserDefaultWarehouse(data.info.default_warehouse));
                dispatch(changeUserDefaultFirm(data.info.default_firm));
                dispatch(changeUserStaffMember(data.info.staff_member));

                // Обробка даних кошика
                let res = data.basket;
                let actual_basket = res.data.items ? [...res.data.items] : [];
                let actualOrderID = res.data.basketOrderID || 0;
                let actual_count = res.data.basketCount || 0;
                let actual_amount = res.data.basketAmount || 0;
                let actual_amount_in = res.data.basketAmountIn || 0;
                let actual_date = res.data.basketDate || today;
                let actualClient =
                    (!res.data.basketClient || res.data.basketClient['id'] === 0)
                    ? data.info.default_client
                    : res.data.basketClient;
                let actualWarehouse = res.data.basketWarehouse;
                let actualFirm =
                    (!res.data.basketFirm || res.data.basketFirm['id'] === 0)
                    ? data.info.default_firm
                    : res.data.basketFirm;
                let actualManager =
                    ((!res.data.basketManager || res.data.basketManager['id'] === 0) && !actualOrderID)
                    ? data.info.staff_member
                    : res.data.basketManager;
                let actualConfirm = res.data.basketConfirm || false;
                let actualPriceScheme = data.info.price
                    ? data.info.price
                    : (res.data.basketPriceScheme ? res.data.basketPriceScheme : "RTL");
                let actualPlanDate = res.data.basketPlanDate || "";
                let actualComent = res.data.basketComent || "";

                // Обробка доставки
                let actualDelivery = {};
                if (data.status === "user") {
                    actualDelivery = {
                    deliveryType: res.data.basketDelivery?.deliveryType || "office",
                    firstname: res.data.basketDelivery?.firstname || data.info.first_name,
                    surname: res.data.basketDelivery?.surname || data.info.last_name,
                    telephone: res.data.basketDelivery?.telephone || data.info.telephone,
                    email: res.data.basketDelivery?.email || data.info.email,
                    regionName: res.data.basketDelivery?.regionName || "",
                    regionRef: res.data.basketDelivery?.regionRef || "",
                    cityName: res.data.basketDelivery?.cityName || "",
                    cityRef: res.data.basketDelivery?.cityRef || "",
                    pointName: res.data.basketDelivery?.pointName || "",
                    pointRef: res.data.basketDelivery?.pointRef || "",
                    adressDelivery: res.data.basketDelivery?.adressDelivery || "",
                    comment: res.data.basketDelivery?.comment || "",
                    };
                } else {
                    actualDelivery = {
                    deliveryType: res.data.basketDelivery?.deliveryType || "office",
                    firstname: res.data.basketDelivery?.firstname || "",
                    surname: res.data.basketDelivery?.surname || "",
                    telephone: res.data.basketDelivery?.telephone || "",
                    email: res.data.basketDelivery?.email || "",
                    regionName: res.data.basketDelivery?.regionName || "",
                    regionRef: res.data.basketDelivery?.regionRef || "",
                    cityName: res.data.basketDelivery?.cityName || "",
                    cityRef: res.data.basketDelivery?.cityRef || "",
                    pointName: res.data.basketDelivery?.pointName || "",
                    pointRef: res.data.basketDelivery?.pointRef || "",
                    adressDelivery: res.data.basketDelivery?.adressDelivery || "",
                    comment: res.data.basketDelivery?.comment || "",
                    };
                }
                
                // Злиття локального кошика з отриманими даними
                let needToUpdateBasket = false;
                if (basketStorage && basketStorage.items.length > 0) {
                    let storage_basket = [...basketStorage.items];
                    let new_items = [];
                    if (actual_basket.length === 0) {
                    actual_basket = [...storage_basket];
                    actual_amount = basketStorage.basketAmount;
                    actual_count = basketStorage.basketCount;
                    } else {
                    actual_amount = 0;
                    actual_count = 0;
                    for (const item of storage_basket) {
                        let found = false;
                        for (const elem of actual_basket) {
                        if (item.id === elem.id) {
                            elem.quantity = Number(elem.quantity) + Number(item.quantity);
                            found = true;
                            break;
                        }
                        }
                        if (!found) {
                        new_items.push(item);
                        }
                    }
                    }
                    actual_basket = [...actual_basket, ...new_items];
                    needToUpdateBasket = true;
                    localStorage.removeItem("basketStorage");
                }
                    
                // Оновлення кошика, якщо дата застаріла або були зміни
                if ((actual_date && Date(actual_date).toString() !== Date(today).toString()) || needToUpdateBasket) {
                    const oldBasket = actual_basket;
                    const updateResult = await apiaxios.post(
                    "/orders/update_cart_by_date/",
                    JSON.stringify(oldBasket),
                    { headers: { "Content-Type": "application/json" }, withCredentials: true }
                    );
                    dispatch(changeBasketUpdateDate(today));
                    dispatch(changeBasketItems([...updateResult.data.basket]));
                    dispatch(changeBasketCount(updateResult.data.basketCount));
                    dispatch(changeBasketAmount(updateResult.data.basketAmount));
                    dispatch(changeBasketAmountIn(updateResult.data.basketAmountIn));
            
                    if (res.data.basketPriceScheme !== "RTL" && !res.data.basketConfirm) {
                    try {
                        const basketRes = await apiaxios.get("/users/update_basket/", {
                        params: { price_scheme: res.data.basketPriceScheme },
                        });
                        dispatch(changeBasketItems(basketRes.data.basket["items"]));
                        dispatch(changeBasketAmount(basketRes.data.basket["basketAmount"]));
                    } catch (err) {
                        console.log(err);
                    }
                    }
                } else {
                    dispatch(changeBasketUpdateDate(actual_date));
                    dispatch(changeBasketItems(actual_basket));
                    dispatch(changeBasketCount(actual_count));
                    dispatch(changeBasketAmount(actual_amount));
                    dispatch(changeBasketAmountIn(actual_amount_in));
                }
                
                dispatch(changeBasketClient(actualClient));
                dispatch(changeBasketWarehouse(actualWarehouse));
                dispatch(changeBasketManager(actualManager));
                dispatch(changeBasketFirm(actualFirm));
                dispatch(changeBasketOrderID(actualOrderID));
                dispatch(changeBasketComent(actualComent));
                dispatch(changeBasketPlanDate(actualPlanDate));
                dispatch(changeBasketConfirm(actualConfirm));
                dispatch(changeBasketPriceScheme(actualPriceScheme));
                dispatch(changeBasketDelivery(actualDelivery));
            } catch (err) {
                console.log(err);
            }
        } else {
            // Логіка для гостя (без access_token)            
            let actual_basket = [];
            let actual_amount = 0;
            let actual_count = 0;
            let actual_date = today;
            if (basketStorage) {
                actual_basket = basketStorage.items;
                actual_amount = basketStorage.basketAmount;
                actual_count = basketStorage.basketCount;
                actual_date = basketStorage.basketDate;
                if (actual_date !== today) {
                    const oldBasket = actual_basket;
                    apiaxios
                        .post(
                            "/orders/update_cart_by_date/",
                            JSON.stringify(oldBasket),
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            },
                            { withCredentials: true }
                        )
                        .then((res) => {
                            dispatch(changeBasketUpdateDate(today));
                            dispatch(changeBasketItems([...res.data.basket]));
                            dispatch(changeBasketCount(res.data.basketCount));
                            dispatch(changeBasketAmount(res.data.basketAmount));
                        });
                } else {
                    dispatch(changeBasketUpdateDate(actual_date));
                    dispatch(changeBasketItems(actual_basket));
                    dispatch(changeBasketCount(actual_count));
                    dispatch(changeBasketAmount(actual_amount));
                }
            } else {
                const newStorage = {
                    basketDate: today,
                    basketAmount: 0,
                    basketCount: 0,
                    items: [],
                };
                localStorage.setItem("basketStorage", JSON.stringify(newStorage));
                dispatch(changeBasketUpdateDate(today));
                dispatch(changeBasketItems([]));
                dispatch(changeBasketCount(0));
                dispatch(changeBasketAmount(0));
            }
            dispatch(changeUserStatus("guest"));
        }
  };