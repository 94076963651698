import { useState } from "react";
import {
  List,
  TextField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  useNotify,
  useRefresh,
  AutocompleteInput,
  NumberField,
  DateField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ImageField,
  FunctionField,
  Datagrid,
  ReferenceField,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";
import { Box } from "@mui/material";
import TextFieldInline from "../commonRA/TextFieldInline";
import DateFieldInline from "../commonRA/DateFieldInline";
import BooleanFieldInline from "../commonRA/BooleanFieldInline";
import ProductListPath from "../../../components/ProductsList/ProductListPath";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
];

export const ProductsList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" />
      <ImageField source="full_image" title="Зображення" />
      <TextField source="name" />
      <TextField source="articul" />
      <TextField source="product_code" />
      <NumberField source="categoryID" />
      <ReferenceField
        source="brand"
        reference="catalog/get_brands"
        label="Виробник"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="warranty" />
      <NumberField source="count_of_images" />
      <TextField source="price_im" />
      <TextField source="recommendable_price" />
      <TextField source="retail_price_uah" />
      <TextField source="bonus" />
      <TextField source="brief_description" />
      <TextField source="country" />
      <TextField source="koduktved" />
      <TextField source="EAN" />
      <DateField source="date_modified" />
      {/* <ArrayField source="filters">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField> */}
      {/* <ArrayField source="prices">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField> */}
      {/* <ArrayField source="sale_prices">
        <SingleFieldList>
          <ChipField source="id" />
        </SingleFieldList>
      </ArrayField> */}
      {/* <ArrayField source="options">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField> */}
      <BooleanField source="xit" />
      <BooleanField source="onoff" />
      <BooleanField source="all_filds_done" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const ProductsShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <Show {...props}>
        <SimpleShowLayout>
          <Box>
            <Box mb={1}>
              <FunctionField
                label="Бонус брейн:"
                render={(record) => (
                  <ProductListPath
                    categoryID={record.categoryID}
                    name={record.name}
                  />
                )}
              />
            </Box>
            <Box display="flex">
              <Box display="flex">
                <ImageField source="full_image" title="Зображення" />
              </Box>
              <Box>
                {/* <Box display="flex">
                  <TextFieldInline source="name" label="Назва:" />
                </Box> */}
                <Box display="flex">
                  <TextFieldInline source="id" label="ID:" />
                  <TextFieldInline source="articul" label="Артикул:" />
                  <TextFieldInline source="product_code" label="Код брейн:" />
                  <DateFieldInline
                    source="date_modified"
                    label="Оновлювалось:"
                  />
                  <BooleanFieldInline source="onoff" label="Наявність:" />
                </Box>
                <Box display="flex">
                  <TextFieldInline source="price_im" label="Ціна:" />
                  <FunctionField
                    label="Рекомендована ціна:"
                    render={(record) =>
                      Number(record.recommendable_price) !== 0 ? (
                        <TextFieldInline
                          source="recommendable_price"
                          label="Рекомендована ціна:"
                        />
                      ) : null
                    }
                  />
                  <FunctionField
                    label="Бонус брейн:"
                    render={(record) =>
                      Number(record.bonus) !== 0 ? (
                        <TextFieldInline source="bonus" label="Бонус брейн:" />
                      ) : null
                    }
                  />
                </Box>
                <Box display="flex">
                  <TextFieldInline source="warranty" label="Гарантія:" />
                  <TextFieldInline source="country" label="Країна:" />
                  <TextFieldInline source="koduktved" label="Код УКТВЕД:" />
                  <TextFieldInline source="EAN" label="EAN:" />
                </Box>
                <Box display="flex">
                  <TextFieldInline
                    source="brief_description"
                    label="Короткий опис:"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <ArrayField source="prices" label="Ціни вхідні">
            <Datagrid>
              <TextField source="vendor" label="Постачальник" />
              <TextField source="vendor_code" label="Код постачальника" />
              <ReferenceField
                source="brand"
                reference="catalog/get_brands_vendors"
                label="Виробник по постачальнику"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="brand_catalog"
                reference="catalog/get_brands"
                label="Виробник по каталогу"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="price" label="Ціна" />
              <TextField source="price_bez_pdv" label="Ціна без ПДВ" />
              <TextField source="quantity" label="Кількість" />
              <DateField source="delivery_date" label="На коли" />
              <BooleanField source="available" label="Доступність" />
              <BooleanField source="blocked" label="Забалоковано" />
              <ActionsField />
            </Datagrid>
          </ArrayField>
          <ArrayField source="sale_prices" label="Ціни вихідні">
            <Datagrid>
              <TextField source="code" label="Категорія ціни" />
              <TextField source="price" label="Ціна" />
              <DateField source="change_date" label="Дата оновлення" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="options">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <ArrayField source="filters">
            <SingleFieldList>
              <ChipField source="id" />
            </SingleFieldList>
          </ArrayField>
          <BooleanField source="xit" />
          <BooleanField source="all_filds_done" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="name" label="Назва" />
    <TextInput source="full_name" label="Повна назва" />
    <TextInput source="adress" label="Адреса" />
    <TextInput source="adress_post" label="Поштова адреса" />
    <TextInput source="phone" label="Телефон" />
    <TextInput source="email" label="Еmail" />
    <TextInput source="EDRPOU" label="Код ЄДРПОУ" />
    <TextInput source="IPN" label="ІПН" />
    <ReferenceInput source="account" reference="firms/get_accounts">
      <AutocompleteInput optionText="account" label="Рахунок" />
    </ReferenceInput>
    <ReferenceInput source="director" reference="staff/get_staff">
      <AutocompleteInput optionText="name" label="Директор" />
    </ReferenceInput>
    <TextInput source="tax" label="Податкова група" />
    <TextInput source="registration" label="Реєстрація" />
    <TextInput source="fiscal_number" label="Фіскальний номер" />
    <TextInput source="prefix" label="Префікс" />
    <BooleanInput source="blocking" />
    <TextInput
      source="code1C"
      slotProps={{
        input: {
          disabled: true,
        },
      }}
    />
  </>
);

export const FirmsEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Редагування картки фірми"
    >
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const FirmsCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки фірми"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
