import css from "./Header.module.css";
import { useLocation } from "react-router-dom";
import CountCircle from "../CountCircle/CountCircle";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useContext } from "react";
import modalStatusContext from "../../context/modalStatusContext";

const CartIcon = () => {
  const basketCount = useSelector((state) => state.basket.count);
  const { openModal } = useContext(modalStatusContext);
  const location = useLocation();
  let admin = false;
  let size = "30";
  if (location.pathname.startsWith("/admin_portal/")) {
    admin = true;
    size = "25";
  }

  return (
    <>
      {!admin && (
        <div
          className={css["header-icon"]}
          id="basket"
          onClick={!admin ? openModal : undefined}
        >
          <svg width={size} height={size}>
            <use xlinkHref="#svg-icon__cart" />
          </svg>
          <div className={css["header-icon-label"]}>КОШИК</div>
          {basketCount > 0 && (
            <div className={css["header-basket_circle"]}>
              <CountCircle count={basketCount} />
            </div>
          )}
        </div>
      )}
      {admin && (
        <div className={css["header-icon-admin"]}>
          <svg width={size} height={size}>
            <use xlinkHref="#svg-icon__cart" />
          </svg>
          {basketCount > 0 && (
            <div className={css["header-basket_circle"]}>
              <CountCircle count={basketCount} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CartIcon;
