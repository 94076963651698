import apiaxios from "../../common/apiaxios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import "react-toastify/dist/ReactToastify.css";
import css from "./ProductFavoriteCompare.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeUserCompareCount,
  changeUserFavoriteCount,
} from "../../redux/userSlice";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import SelectFromList from "../control/SelectFromList/SelectFromList";

const ProductFavoriteCompare = ({
  type,
  id,
  name,
  favoriteStatus,
  compareStatus,
  starStatus = false,
}) => {
  const userStatus = useSelector((state) => state.user.status);
  const dispatch = useDispatch();
  const [favorite, setFavorite] = useState(favoriteStatus);
  const [compare, setCompare] = useState(compareStatus);
  const [star, setStar] = useState(starStatus);
  const [data, setData] = useState([]);
  const [addRow, setAddRow] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setAddRow(false);
  };

  const favoriteClick = () => {
    if (userStatus === "guest") {
      toast.error("Доступно тільки зареєстрованим користувачам!");
    } else {
      apiaxios.post("/users/post_product_favorite?id=" + id).then((res) => {
        dispatch(changeUserFavoriteCount(res.data["favoriteCount"]));
        setFavorite(!favorite);
      });
    }
  };

  const compareClick = () => {
    if (userStatus === "guest") {
      toast.error("Доступно тільки зареєстрованим користувачам!");
    } else {
      apiaxios.post("/users/post_product_compare?id=" + id).then((res) => {
        dispatch(changeUserCompareCount(res.data["compareCount"]));
        setCompare(!compare);
      });
    }
  };

  const starClick = () => {
    if (userStatus === "guest") {
      toast.error("Доступно тільки зареєстрованим користувачам!");
    } else {
      apiaxios.get("/sources/get_star_item?id=" + id).then((res) => {
        setData(res.data);
        if (res.data.length === 0) {
          setAddRow(true);
        }
      });
      handleOpen(true);
    }
  };

  const changeQuantity = (value, id) => {
    const new_data = data.map((item) => {
      if (item["warehouse"]["id"] === id) {
        item["quantity"] = value;
      }
      return item;
    });
    setData(new_data);
  };

  const saveStar = () => {
    let updatedData = data;
    if (addRow) {
      updatedData = [
        ...data,
        {
          id: id,
          warehouse: { id: warehouse["id"], name: warehouse["name"] },
          quantity: quantity,
        },
      ];
    }

    // Об'єднуємо рядки з однаковими warehouse["id"] та id
    const mergedData = updatedData.reduce((acc, item) => {
      const existingItem = acc.find(
        (entry) =>
          entry.id === item.id && entry.warehouse.id === item.warehouse.id
      );

      if (existingItem) {
        // Якщо такий елемент вже є, додаємо кількість
        existingItem.quantity += item.quantity;
      } else {
        // Якщо такого елемента немає, додаємо його до результату
        acc.push({ ...item });
      }

      return acc;
    }, []);

    setData(mergedData); // Оновлюємо стан з об'єднаними даними

    apiaxios
      .post(
        "/sources/save_star_item/",
        mergedData,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then(() => {
        setStar(true);
      });
    handleClose();
  };

  const removeClick = () => {
    // eslint-disable-next-line no-restricted-globals
    const isConfirmed = confirm("Ви впевнені, що хочете видалити картку?");
    if (isConfirmed) {
      apiaxios.post("/catalog/remove_product?id=" + id).then((res) => {
        if (res.data["result"] === "card deleted!") {
          window.location.reload();
        } else {
          alert("Помилка: не вдалося видалити - картка буда задіяна!");
        }
      });
    }
  };

  return (
    <>
      <div
        className={
          (type = "line"
            ? css["favorite-compare-item-line"]
            : css["favorite-compare-item"])
        }
        onClick={favoriteClick}
      >
        <div
          className={
            favorite
              ? css["favorite-compare-item-favorite"]
              : css["favorite-compare-item-not-favorite"]
          }
        >
          <svg
            width={window.innerWidth >= 500 ? "18px" : "15px"}
            height={window.innerWidth >= 500 ? "18px" : "15px"}
          >
            <use xlinkHref="#svg-icon__heart" />
          </svg>
        </div>
      </div>
      <div
        className={
          (type = "line"
            ? css["favorite-compare-item-line"]
            : css["favorite-compare-item"])
        }
        onClick={compareClick}
      >
        <div
          className={
            compare
              ? css["favorite-compare-item-compare"]
              : css["favorite-compare-item-not-compare"]
          }
        >
          <svg
            width={window.innerWidth >= 500 ? "20px" : "17px"}
            height={window.innerWidth >= 500 ? "20px" : "17px"}
          >
            <use xlinkHref="#svg-icon__libra" />
          </svg>
        </div>
      </div>
      {(userStatus === "super" || userStatus === "staff") && (
        <div
          className={
            (type = "line"
              ? css["favorite-compare-item-line"]
              : css["favorite-compare-item"])
          }
          onClick={starClick}
        >
          <div
            className={
              star
                ? css["favorite-compare-item-star"]
                : css["favorite-compare-item-not-star"]
            }
          >
            <svg
              width={window.innerWidth >= 500 ? "20px" : "17px"}
              height={window.innerWidth >= 500 ? "20px" : "17px"}
            >
              <use xlinkHref="#svg-icon__star" />
            </svg>
          </div>
        </div>
      )}
      {userStatus === "super" && (
        <div
          className={
            (type = "line"
              ? css["favorite-compare-item-line"]
              : css["favorite-compare-item"])
          }
          onClick={removeClick}
        >
          <div className={css["item-remove"]}>
            <svg
              width={window.innerWidth >= 500 ? "20px" : "17px"}
              height={window.innerWidth >= 500 ? "20px" : "17px"}
            >
              <use xlinkHref="#svg-icon__remove" />
            </svg>
          </div>
        </div>
      )}
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent style={{ padding: 0 }}>
          <Card>
            <CardHeader title="Робота зі списком обов'язкових товарів на складі" />
            <CardContent style={{ fontSize: "0.8rem" }}>
              <Box style={{ fontSize: "1rem", marginBottom: "5px" }}>
                {name}
              </Box>
              <Divider style={{ marginBottom: "15px" }} />
              {data
                .filter((item) => item["quantity"] !== 0)
                .map((item, index) => (
                  <Box
                    display="flex"
                    alignItems={"center"}
                    marginBottom={1}
                    key={item["warehouse"]["name"]}
                  >
                    <Box display="flex" alignItems={"center"}>
                      <div style={{ marginRight: "10px" }}>Склад:</div>
                      <div className={css["input-warehouse-block"]}>
                        {item["warehouse"]["name"]}
                      </div>
                    </Box>
                    <Box>у кількості:</Box>
                    <input
                      className={css["edit-list-name-input"]}
                      type="number"
                      min="1" // Додаємо атрибут min для заборони від'ємних значень
                      value={item["quantity"]}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value >= 1) {
                          changeQuantity(value, item["warehouse"]["id"]);
                        }
                      }}
                    />
                    <Box
                      className={css["remove-warehouse-icon"]}
                      onClick={() => changeQuantity(0, item["warehouse"]["id"])}
                    >
                      <svg
                        width={window.innerWidth >= 500 ? "15px" : "15px"}
                        height={window.innerWidth >= 500 ? "15px" : "15px"}
                      >
                        <use xlinkHref="#svg-icon__remove" />
                      </svg>
                    </Box>
                    {index === data.length - 1 && !addRow && (
                      <Box className={css["remove-warehouse-icon"]}>
                        <AddIcon
                          sx={{ fontSize: 25, color: "green" }}
                          onClick={() => {
                            setAddRow(true);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                ))}
              {addRow && (
                <Box display="flex">
                  <Box>
                    <SelectFromList
                      requestPath={"/sources/get_warehouses/"}
                      label={"Склад:"}
                      value={warehouse}
                      setValue={setWarehouse}
                      width="250px"
                      clear={false}
                    />
                  </Box>
                  <Box>у кількості:</Box>
                  <input
                    className={css["edit-list-name-input"]}
                    type="number"
                    min="1" // Додаємо атрибут min для заборони від'ємних значень
                    value={quantity}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value >= 1) {
                        setQuantity(value);
                      }
                    }}
                  />
                </Box>
              )}
              <Divider style={{ marginTop: "15px" }} />
              <Box marginTop={1}>
                <Button onClick={saveStar}>Зберегти</Button>
                <Button onClick={handleClose}>Закрити</Button>
              </Box>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProductFavoriteCompare;
