import { Card, CardContent } from "@mui/material";
import ProductsListPage from "../../pages/ProductsListPage";

export const CatalogStartPage = () => (
  <>
    {/* <Card sx={{ mb: 2 }}>
      <CardHeader title="Список очікування користувача" />
      <CardContent>Тут буде список...</CardContent>
    </Card> */}
    <Card>
      {/* <CardHeader title="Нові надходження каталогу" /> */}
      <CardContent>
        <ProductsListPage type={"new"} />
      </CardContent>
    </Card>
  </>
);
