import apiaxios from "../common/apiaxios";

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const response = await apiaxios.post("/token/", { username, password });
        const { access, refresh } = response.data;

      // Зберігаємо токени у LocalStorage
      localStorage.setItem("access_token", access);
      localStorage.setItem("refresh_token", refresh);

      // Додаємо токен до заголовків axios
      apiaxios.defaults.headers.common["Authorization"] = `Bearer ${access}`;
      return Promise.resolve();
    } catch {
      return Promise.reject("Неправильний логін або пароль");
    }
  },

  logout: () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    delete apiaxios.defaults.headers.common["Authorization"];
    return Promise.resolve();
  },

  checkAuth: async () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      return Promise.reject();
    }
    const userStatus = localStorage.getItem("status");
    if (userStatus !== 'super' && userStatus !== 'staff') {
      return Promise.reject();
    }
    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.response?.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      delete apiaxios.defaults.headers.common["Authorization"];
      return Promise.reject();
    }
    return Promise.resolve();
  },
  
  // getPermissions: () => {
  //   const userStatus = store.getState().user.status;
  //   console.log(userStatus);
  //   if (userStatus !== 'super' && userStatus !== 'staff') {
  //     return Promise.reject("Access denied!");
  //   }
  //   return Promise.resolve(userStatus);
  // }
};

export default authProvider;
