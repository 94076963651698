import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { IconContext } from "react-icons";
import css from "./Auth.module.css";
import apiaxios from "../../common/apiaxios";
import { useContext, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import modalStatusContext from "../../context/modalStatusContext";
import Modal from "../../common/Modal";
import Registration from "./Registration";
import RestorePassword from "./RestorePassword";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeUserStatus } from "../../redux/userSlice";
import { getStartUserData } from "./getStartUserData";

const Login = ({ modalClose }) => {
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passShow, setPassShow] = useState(false);
  const [result, setResult] = useState("start");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalIsOpen, closeModal, contentModal } =
    useContext(modalStatusContext);

  window.scrollTo({ top: 0, behavior: "smooth" });

  const submit = async (e) => {
    e.preventDefault();
    const user = {
      username: username,
      password: password,
    };
    await apiaxios
      .post(
        "/token/",
        user,
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        apiaxios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data["access"]}`;
        setResult("success");
        getStartUserData(dispatch);
        navigate("/", { replace: true });
      })
      .catch(() => {
        setResult("error");
        dispatch(changeUserStatus("guest"));
      });
  };

  const passwordField = document.getElementById("password");

  const passwordShow = () => {
    if (passwordField.type === "password") {
      passwordField.type = "text";
      setPassShow(true);
    } else {
      passwordField.type = "password";
      setPassShow(false);
    }
  };

  return (
    <div className={css["reg-container"]}>
      <div className="page-title">Вхід</div>
      {searchParams.get("type") === "reg" && (
        <div className={css["restore-reg-message"]}>
          Реєстрація пройшла успішно!
        </div>
      )}
      {searchParams.get("type") === "restore" && (
        <div className={css["restore-reg-message"]}>
          Ваш пароль було скинуто і відправлено Вам на пошту новий, який Ви
          зможете змінити у особистому кабінеті.
        </div>
      )}
      {result === "error" && (
        <div className={css["restore-reg-message"]}>
          Неправельний логін або пароль!
        </div>
      )}

      <div className={css["modal-x"]} onClick={modalClose}>
        <svg width="20" height="20">
          <use xlinkHref="#svg-icon__close" />
        </svg>
      </div>
      <form onSubmit={submit}>
        <div className={css["form-group"]}>
          <label>Електронна пошта:</label>
          <input
            className={css["form-control"]}
            name="username"
            type="email"
            autoComplete="username"
            value={username}
            required
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className={css["form-group"]}>
          <div>
            <label>Пароль:</label>
          </div>
          <div className={css["password-eye-block"]}>
            <input
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
              className={css["form-control"]}
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className={css["password-btn-eye"]}
              onMouseDown={passwordShow}
              onMouseUp={passwordShow}
            >
              <IconContext.Provider value={{ size: "0.9em" }}>
                {passShow ? <FaRegEye /> : <FaRegEyeSlash />}
              </IconContext.Provider>
            </div>
          </div>
        </div>
        <div className={css["form-footer"]}>
          <div className={css["form-footer-link-block"]}>
            <NavLink to="/restore_password" className={css["form-footer-link"]}>
              <div>Відновити пароль?</div>
            </NavLink>
            <NavLink to="/registration" className={css["form-footer-link"]}>
              <div>Реєстрація</div>
            </NavLink>
          </div>
          <div>
            <button type="submit" className={css["form-btn"]}>
              Увійти
            </button>
          </div>
        </div>
        {modalIsOpen && contentModal === "reg" && (
          <Modal>
            <div className={css["modal-basket-container"]}>
              <Registration modalClose={closeModal} />
            </div>
          </Modal>
        )}
        {modalIsOpen && contentModal === "restore" && (
          <Modal>
            <div className={css["modal-basket-container"]}>
              <RestorePassword modalClose={closeModal} />
            </div>
          </Modal>
        )}
      </form>
    </div>
  );
};

export default Login;
