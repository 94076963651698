import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { changeBasketDelivery } from "../../redux/basketSlice";

const BasketDeliveryFormObserver = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeBasketDelivery({ ...values }));
  }, [values, dispatch]);

  return null;
};

export default BasketDeliveryFormObserver;
