import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./userSlice";
import { basketReducer } from "./basketSlice";
// import { basketReducer, saveBasketMiddleware } from "./basketSlice";

export const store = configureStore({
    reducer: {
        user: userReducer,
        basket: basketReducer,
  },
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(saveBasketMiddleware)
});