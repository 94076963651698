import { useState } from "react";
import {
  List,
  TextField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  DateField,
  NumberField,
  BooleanField,
  useNotify,
  useRefresh,
  DateInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
];

export const StaffList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <NumberField source="id" />
      <TextField source="name" />
      <TextField source="surename" />
      <TextField source="firstname" />
      <TextField source="secondname" />
      <TextField source="job_title" />
      <BooleanField source="co_founder" />
      <TextField source="name_print" />
      <TextField source="name_doverka" />
      <TextField source="passport_code" />
      <TextField source="passport_number" />
      <TextField source="passport_who_give" />
      <DateField source="passport_when_give" />
      <TextField source="salary" />
      <BooleanField source="active" />
      <TextField source="check_from_prefix" />
      <TextField source="code1C" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const StaffShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Перегляд картки співробітника"
    >
      <Show {...props}>
        <SimpleShowLayout>
          <NumberField source="id" />
          <TextField source="name" />
          <TextField source="surename" />
          <TextField source="firstname" />
          <TextField source="secondname" />
          <TextField source="job_title" />
          <BooleanField source="co_founder" />
          <TextField source="name_print" />
          <TextField source="name_doverka" />
          <TextField source="passport_code" />
          <TextField source="passport_number" />
          <TextField source="passport_who_give" />
          <DateField source="passport_when_give" />
          <TextField source="salary" />
          <BooleanField source="active" />
          <TextField source="check_from_prefix" />
          <TextField source="code1C" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="name" />
    <TextInput source="surename" />
    <TextInput source="firstname" />
    <TextInput source="secondname" />
    <TextInput source="job_title" />
    <BooleanInput source="co_founder" />
    <TextInput source="name_print" />
    <TextInput source="name_doverka" />
    <TextInput source="passport_code" />
    <TextInput source="passport_number" />
    <TextInput source="passport_who_give" />
    <DateInput source="passport_when_give" />
    <TextInput source="salary" />
    <BooleanInput source="active" />
    <TextInput source="check_from_prefix" />
    <TextInput
      source="code1C"
      slotProps={{
        input: {
          disabled: true,
        },
      }}
    />
  </>
);

export const StaffEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Редагування картки співробітника"
    >
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const StaffCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Клієнта успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки співробітника"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
