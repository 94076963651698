import { useSearchParams, useParams } from "react-router-dom";

const useProductsListAPIPath = ({
  perPage,
  page,
  sortTypePrice,
  minPrice,
  maxPrice,
  priceScheme,
  type,
  setXit,
}) => {
  const [searchParams] = useSearchParams();
  const { categoryID } = useParams();

  const filtersPath = searchParams.get("filters") || "";
  let path = "";

  if (searchParams.get("search") || categoryID) {
    path =
      "/catalog/get_products_list?perPage=" +
      perPage +
      "&page=" +
      page +
      "&category=" +
      (categoryID) +
      "&search=" +
      (searchParams.get("search")) +
      "&sort=" +
      sortTypePrice +
      "&filters=" +
      filtersPath +
      "&gt=" +
      minPrice +
      "&lt=" +
      maxPrice +
      "&price_scheme=" +
      priceScheme +"&xit=0";
  } else {
    if (type === "xit") {
      path =
        "/catalog/get_products_list?perPage=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        (searchParams.get("search")) +
        "&sort=" +
        sortTypePrice +
        "&filters=" +
        filtersPath +
        "&gt=" +
        0 +
        "&lt=" +
        maxPrice +
        "&price_scheme=" +
        priceScheme + "&xit=1";
    }  else if (type === "new") {
      path =
        "/catalog/get_products_list?perPage=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        (searchParams.get("search")) +
        "&sort=" +
        sortTypePrice +
        "&filters=" +
        filtersPath +
        "&gt=" +
        0 +
        "&lt=" +
        maxPrice +
        "&price_scheme=" +
        priceScheme +"&new=1";
    } else {
      path =
        "/catalog/get_products_list?perPage=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        (searchParams.get("search")) +
        "&sort=" +
        sortTypePrice +
        "&filters=" +
        filtersPath +
        "&gt=" +
        0 +
        "&lt=" +
        maxPrice +
        "&price_scheme=" +
        priceScheme + "&xit=0";
    }
  }

  return path;
};

export default useProductsListAPIPath;