import React from "react";
import { Typography, Box } from "@mui/material";
import { BooleanField } from "react-admin";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const BooleanFieldInline = ({ source, label }) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="caption" sx={{ mr: 1, ml: 1, fontWeight: "bold" }}>
        {label}
      </Typography>
      <BooleanField
        source={source}
        TrueIcon={() => (
          <CheckIcon style={{ color: "green", fontSize: "17px" }} />
        )}
        FalseIcon={() => (
          <CloseIcon style={{ color: "red", fontSize: "17px" }} />
        )}
      />
    </Box>
  );
};

export default BooleanFieldInline;
