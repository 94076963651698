import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiaxios from "../../common/apiaxios";
import DateInterval from "../../common/DateInterval";
import DateSelect from "../../common/DateSelect";
import css from "./Orders.module.css";
import { BsCalendarDay } from "react-icons/bs";
import { IconContext } from "react-icons";
import OrderInfoStaff from "./OrderInfoStaff";
import OrderInfo from "./OrderInfo";
import { useSelector } from "react-redux";

const OrdersList = ({ type }) => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [statuses, setStatuses] = useState();
  const [statusList, setStatusList] = useState([]);
  const [statusOnOff, setStatusOnOff] = useState(false);
  const [activeStatus, setActiveStatus] = useState("Всі");
  const [activeStatusKey, setActiveStatusKey] = useState(null);
  const [dateIntervalActive, setDateIntervalActive] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date("2024-01-01 00:00:00.000").toISOString()
  );
  const correctTime = (time) => {
    const corTime = new Date(time);
    const timezoneOffset = corTime.getTimezoneOffset() * 60000; // Перетворюємо хвилини в мілісекунди
    const localTime = corTime.getTime() - timezoneOffset; // Отримуємо локальний час
    const localDate = new Date(localTime); // Створюємо нову дату з локальним часом
    return localDate.toISOString();
  };
  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 999);
  const [endDate, setEndDate] = useState(currentDate.toISOString());
  const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    apiaxios
      .get("/orders/get_orders_statuses/")
      .then((res) => {
        setStatuses(res.data);
        setStatusList(["Всі", ...Object.values(res.data)]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let params =
      "?from=" + correctTime(startDate) + "&to=" + correctTime(endDate);
    if (activeStatusKey) {
      params += "&status=" + activeStatusKey;
    }
    navigate(params, { replace: true });
    if (localStorage.getItem("access_token") && type === "order_user") {
      apiaxios
        .get("/orders/get_user_orders/" + params)
        .then((res) => {
          setOrders(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      localStorage.getItem("access_token") &&
      (userStatus === "staff" || userStatus === "super") &&
      type === "orders_list"
    ) {
      apiaxios
        .get("/orders/get_orders/" + params)
        .then((res) => {
          setOrders(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userStatus, type, startDate, endDate, navigate, activeStatusKey]);

  const selectStatus = (e) => {
    setActiveStatus(e.currentTarget.id);
    let done = 0;
    for (let key in statuses) {
      if (statuses[key] === e.currentTarget.id) {
        setActiveStatusKey(key);
        done = 1;
      }
    }
    if (!done) {
      setActiveStatusKey(null);
    }
    setStatusOnOff(!statusOnOff);
  };

  return (
    <div className={css["orders-list-container"]}>
      {type === "orders_list" && (
        <div className="page-title">Журнал замовлень</div>
      )}
      <div className={css["orders-list-options-container"]}>
        <div className={css["orders-list-options-date-container"]}>
          <div className={css["orders-list-options-date-item"]}>з</div>
          <div className={css["orders-list-options-date-item"]}>
            <DateSelect
              selectedDate={startDate}
              setSelectedDate={setStartDate}
            />
          </div>
          <div className={css["orders-list-options-date-item"]}>по</div>
          <div className={css["orders-list-options-date-item"]}>
            <DateSelect selectedDate={endDate} setSelectedDate={setEndDate} />
          </div>
          <div>
            <div
              className={css["orders-list-options-calendar-btn"]}
              onMouseEnter={() => {
                setDateIntervalActive(true);
              }}
              onMouseLeave={() => {
                setDateIntervalActive(false);
              }}
            >
              <IconContext.Provider value={{ size: "1.5em" }}>
                <BsCalendarDay />
              </IconContext.Provider>
              <div>
                {dateIntervalActive && (
                  <div className={css["orders-list-options-dateinterval"]}>
                    <DateInterval
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={css["orders-list-options-status-container"]}>
          <div>Статус:</div>
          <div
            className={css["orders-list-options-status-select"]}
            onMouseEnter={() => {
              setStatusOnOff(true);
            }}
            onMouseLeave={() => {
              setStatusOnOff(false);
            }}
          >
            {activeStatus}
            {statusOnOff && (
              <div className={css["status-select-container"]}>
                {statusList.map((item) => (
                  <div
                    className={css["status-select-item"]}
                    id={item}
                    key={item}
                    onClick={selectStatus}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {orders.length === 0 && <div>Список замовленнь порожній...</div>}
      {orders.length !== 0 &&
        (userStatus === "staff" || userStatus === "super") &&
        type === "orders_list" && (
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th>Дата</th>
                <th>Клієнт</th>
                <th>Сума</th>
                <th>Менеджер</th>
                <th>Автор</th>
                <th>Статус</th>
                <th>Редагувати</th>
              </tr>
            </thead>
            <tbody>
              {orders.length !== 0 &&
                orders.map((item) => (
                  <OrderInfoStaff
                    key={item.date}
                    item={item}
                    statuses={statuses}
                    statusList={statusList.slice(1)}
                  />
                ))}
            </tbody>
          </table>
        )}

      {orders.length !== 0 &&
        orders.map((item) => (
          <div key={item.date}>
            {userStatus !== "staff" && userStatus !== "super" && (
              <OrderInfo
                item={item}
                statuses={statuses}
                statusList={statusList.slice(1)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default OrdersList;
