import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Main";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Delivery from "./pages/Delivery";
import SC from "./pages/SC";
import Contact from "./pages/Contact";
import About from "./pages/About";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import GroupMenu from "./components/GroupMenu/GroupMenu";
import Profile from "./components/Profile/Profile";
import OrderNew from "./components/Orders/OrderNew";
import Utility from "./components/Profile/Utility";
import Info from "./components/Profile/Info";
import Login from "./components/Auth/Login";
import Registration from "./components/Auth/Registration";
import RestorePassword from "./components/Auth/RestorePassword";
import ProductsListPage from "./pages/ProductsListPage";
import ProductsCompareDetails from "./components/ProductsCompareDetails/ProductsCompareDetails";
import Statistic from "./components/Profile/Statistic";
import OrdersList from "./components/Orders/OrdersList";
import Portal from "./pages/Portal";
import Sources from "./components/Dictionaries/Sources";
import SourceItem from "./components/Dictionaries/SourceItem";
import VendorsCategories from "./components/Dictionaries/VendorsCategories";
import ProductDublicate from "./components/ProductDublicate/ProductDublicate";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStartUserData } from "./components/Auth/getStartUserData";
import DictionaryList from "./components/Dictionaries/DictionaryList";
import PriceList from "./pages/PriceList";
import SearchProductInfo from "./components/SearchProductInfo/SearchProductInfo";
import AddProductCard from "./components/ProductCard/AddProductCard";
import PDVInventory from "./components/PDVInventory/PDVInventory";
import AdminPortal from "./portal/AdminPortal";
import ProductDublicateByBrand from "./components/ProductDublicate/ProductDublicateByBrand";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      { index: true, element: <Home /> },
      { path: "delivery", element: <Delivery /> },
      { path: "sc", element: <SC /> },
      { path: "contacts", element: <Contact /> },
      { path: "about", element: <About /> },

      { path: "login", element: <Login /> },
      { path: "registration", element: <Registration /> },
      { path: "restore_password", element: <RestorePassword /> },

      { path: "profile/orders", element: <Profile active_menu={"order_user"} /> },
      { path: "profile/orders_list", element: <Profile active_menu={"orders_list"} /> },
      { path: "profile/favorite", element: <Profile active_menu={"favorite"} /> },
      { path: "profile/compare", element: <Profile active_menu={"compare"} /> },
      { path: "profile/info", element: <Profile active_menu={"info"} /> },
      { path: "profile/utility", element: <Profile active_menu={"utility"} /> },
      { path: "profile/info_mob", element: <Info /> },
      { path: "utility", element: <Utility /> },
      { path: "statistic", element: <Statistic /> },
      { path: "order", element: <OrderNew /> },

      { path: "group_menu/:categoryID", element: <GroupMenu /> },
      { path: "product_details/:id", element: <ProductDetails /> },
      { path: "products_list/:categoryID", element: <ProductsListPage /> },
      { path: "search", element: <ProductsListPage /> },
      { path: "compare", element: <ProductsCompareDetails /> },

      {
        path: "portal",
        element: <Portal />,
        children: [
          { index: true, element: <OrdersList type={"orders_list"} /> },
          { path: "orders_list", element: <OrdersList type={"orders_list"} /> },
          { path: "sources_list", element: <Sources /> },
          { path: "sources_list/:id", element: <SourceItem type={"edit"} /> },
          { path: "sources_list/update/:vendor", element: <SourceItem type={"update"} /> },
          { path: "sources_list/create", element: <SourceItem type={"create"} /> },
          { path: "vendors_categories_list", element: <VendorsCategories /> },
          { path: "products_without_category/:categoryID", element: <ProductsListPage /> },
          { path: "product_dublicate", element: <ProductDublicate path={"/catalog/get_vendors_vendor_code/"} /> },
          { path: "product_dublicate_by_product_code", element: <ProductDublicate path={"/catalog/get_duplicate_by_same_product_code/"} /> },
          { path: "product_dublicate_by_brand", element: <ProductDublicateByBrand /> },
          {
            path: "clients_dictionary",
            element: <DictionaryList pageTitle={"Довідник контрагентів"} path={"/partners/get_clients_list/"} type={"client"} />,
          },
          {
            path: "users_dictionary",
            element: <DictionaryList pageTitle={"Довідник користувачів"} path={"/users/get_users_list/"} type={"user"} />,
          },
          {
            path: "staff_dictionary",
            element: <DictionaryList pageTitle={"Довідник співробітників"} path={"/staff/get_staff_list/"} type={"staff"} />,
          },
          {
            path: "firms_dictionary",
            element: <DictionaryList pageTitle={"Довідник фірми"} path={"/firms/get_firms_list/"} type={"firms"} />,
          },
          {
            path: "accounts_dictionary",
            element: <DictionaryList pageTitle={"Довідник розрахункові рахунки та каси"} path={"/firms/get_accounts_list/"} type={"accounts"} />,
          },
          { path: "search_product_info", element: <SearchProductInfo /> },
          {
            path: "create_product_card_manualy",
            element: <AddProductCard nameIn="" articulIn="" categoryIDIn="" countryIn="" codeBrainIn="" briefDescriptionIn="" warrantyIn="" koduktvedIn="" EANIn="" imageIn="" priceImIn="" priceRecIn="" />,
          },
          { path: "get_pdv_inventory", element: <PDVInventory /> },
        ],
      },
      { path: "pricelist", element: <PriceList /> },
    ],
  },
  { path: "/admin_portal/*", element: <AdminPortal basename="/admin_portal" />, },
  { path: "*", element: <NotFound /> },
]
);

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    getStartUserData(dispatch);
  }, [dispatch]);

  return <RouterProvider router={router} future={{
    v7_startTransition: true, v7_relativeSplatPath: true,
  }}/>;
}

export default App;
