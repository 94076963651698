import axios from "axios";

const apiaxios = axios.create({
  baseURL: process.env.REACT_APP_DJANGO_API_URL,
  withCredentials: true,
});

// Запит: додаємо токен з localStorage до кожного запиту
apiaxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Відповідь: при отриманні 401, пробуємо оновити токен та повторити запит
apiaxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry &&
      localStorage.getItem("refresh_token")
    ) {
      originalRequest._retry = true;
      try {
        const refreshResponse = await axios.post(
          process.env.REACT_APP_DJANGO_API_URL + "/token/refresh/",
          { refresh: localStorage.getItem("refresh_token") },
          { headers: { "Content-Type": "application/json" } }
        );
        if (refreshResponse.status === 200) {
          const { access, refresh } = refreshResponse.data;
          localStorage.setItem("access_token", access);
          localStorage.setItem("refresh_token", refresh);
          originalRequest.headers["Authorization"] = `Bearer ${access}`;
          return apiaxios(originalRequest);
        }
      } catch (refreshError) {
        // Очищення токенів якщо оновлення не вдалося
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default apiaxios;
