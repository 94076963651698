import { useState } from "react";
import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  useNotify,
  useRefresh,
  EmailField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
  <ReferenceInput
    source="client_id"
    label="Клієнт"
    reference="partners/get_partners"
    key="client-filter"
  />,
];

export const DeliveryList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" />
      <TextField source="firstname" />
      <TextField source="surname" />
      <TextField source="telephone" />
      <EmailField source="email" />
      <TextField source="regionName" />
      <TextField source="regionRef" />
      <TextField source="cityName" />
      <TextField source="cityRef" />
      <TextField source="pointName" />
      <TextField source="pointRef" />
      <TextField source="adressDelivery" />
      <TextField source="comment" />
      <ReferenceField
        source="client_id"
        reference="partners/get_partners"
        label="Клієнт"
      >
        <TextField source="name" />
      </ReferenceField>
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const DeliveryShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Перегляд картки">
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="firstname" />
          <TextField source="surname" />
          <TextField source="telephone" />
          <EmailField source="email" />
          <TextField source="regionName" />
          <TextField source="regionRef" />
          <TextField source="cityName" />
          <TextField source="cityRef" />
          <TextField source="pointName" />
          <TextField source="pointRef" />
          <TextField source="adressDelivery" />
          <TextField source="comment" />
          <ReferenceField
            source="client_id"
            reference="partners/get_partners"
            label="Клієнт"
          >
            <TextField source="name" />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="firstname" />
    <TextInput source="surename" />
    <TextInput source="telephone" />
    <TextInput source="email" />
    <TextInput source="regionName" />
    <TextInput source="regionRef" />
    <TextInput source="cityName" />
    <TextInput source="cityRef" />
    <TextInput source="pointName" />
    <TextInput source="pointRef" />
    <TextInput source="adressDelivery" />
    <ReferenceInput source="client_id" reference="partners/get_partners">
      <AutocompleteInput optionText="name" label="Клієнт" />
    </ReferenceInput>
    <TextInput source="comment" />
  </>
);

export const DeliveryEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Редагування картки">
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const DeliveryCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
