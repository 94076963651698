import { ListItemText, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import userStatusContext from "../../../context/userStatusContext";
import { useContext } from "react";

const GroupItemPortal = ({ item }) => {
  const { setPage, setMinPrice, setMaxPrice } = useContext(userStatusContext);
  return (
    <>
      <Link
        to={`/admin_portal/catalog/category/${item.categoryID}`}
        style={{ textDecoration: "none", color: "black" }}
        onClick={() => {
          setPage(1);
          setMinPrice(0);
          setMaxPrice(1000000);
        }}
      >
        <MenuItem sx={{ p: 0.5, ml: 1, mr: 0.5 }}>
          <ListItemText
            primary={item.name}
            slotProps={{
              primary: {
                sx: {
                  whiteSpace: "normal", // Дозволяє тексту переноситися
                  wordWrap: "break-word", // Розбиває слова, якщо вони занадто довгі
                  fontSize: 13, // (за потребою) налаштування розміру шрифту
                  fontStyle: "italic", // Робить текст курсивним
                },
              },
            }}
          />
        </MenuItem>
      </Link>
    </>
  );
};

export default GroupItemPortal;
