import { useState, useContext } from "react";
import userStatusContext from "../../context/userStatusContext";
import css from "./CategoriesFilters.module.css";
import { IconContext } from "react-icons";
import { CgCheck, CgChevronDown, CgChevronRight } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { catalogNavigate } from "../../common/catalogNavigate";

const CategoriesFiltersGroup = ({ allFilters, name }) => {
  const {
    sortTypePrice,
    search,
    filters,
    setFilters,
    perPage,
    setPage,
    minPrice,
    maxPrice,
  } = useContext(userStatusContext);
  const navigate = useNavigate();
  let showGroup = false;
  for (let filter of allFilters) {
    if (filters.includes(filter.filterID)) {
      showGroup = true;
      break;
    }
  }
  const [show, setShow] = useState(showGroup ? true : false);

  const handleClikFilter = (e) => {
    const newList = [...filters];
    if (newList.includes(e.currentTarget.id)) {
      newList[newList.indexOf(e.currentTarget.id)] = "";
    } else {
      newList.push(e.currentTarget.id);
    }
    const newListAfter = [];
    for (const fil of newList) {
      if (fil) {
        newListAfter.push(fil);
      }
    }
    newListAfter.sort();
    catalogNavigate(navigate, {
      filters: newListAfter,
      perPage: perPage,
      page: 1,
      sort: sortTypePrice,
      search: search,
      minPrice: minPrice,
      maxPrice: maxPrice,
    });
    setPage(1);
    setFilters(newListAfter);
  };

  return (
    <>
      {allFilters.length > 0 && (
        <div className={css["filter-block-group"]}>
          <div
            className={css["filter-block-button"]}
            onClick={() => {
              setShow(!show);
            }}
          >
            {show ? (
              <IconContext.Provider value={{ size: "1em" }}>
                <div>
                  <CgChevronDown />
                </div>
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={{ size: "1em" }}>
                <div>
                  <CgChevronRight />
                </div>
              </IconContext.Provider>
            )}
          </div>
          <div
            className={css["filter-block-group-title"]}
            onClick={() => {
              setShow(!show);
            }}
          >
            {name}
          </div>
          {show && (
            <ul>
              {allFilters.map((filter) => (
                <li
                  key={filter.filterID}
                  className={css["filter-block-item"]}
                  onClick={handleClikFilter}
                  id={filter.filterID}
                >
                  <div className={css["v_menu"]}>
                    <div className={css["v_block"]}>
                      {filters.includes(filter.filterID) && (
                        <div>
                          <IconContext.Provider value={{ size: "1em" }}>
                            <div>
                              <CgCheck />
                            </div>
                          </IconContext.Provider>
                        </div>
                      )}
                    </div>
                    <div className={css["filter-name"]} id={filter.filterName}>
                      {filter.filterName}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default CategoriesFiltersGroup;
