import { useState } from "react";
import {
  List,
  TextField,
  ReferenceField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  EmailField,
  BooleanField,
  useNotify,
  useRefresh,
  AutocompleteInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
];

export const FirmsList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" label="Код" />
      <TextField source="name" label="Назва" />
      <TextField source="full_name" label="Повна назва" />
      <TextField source="adress" label="Адреса" />
      <TextField source="adress_post" label="Поштова адреса" />
      <TextField source="phone" label="Телефон" />
      <EmailField source="email" label="Еmail" />
      <TextField source="EDRPOU" label="Код ЄДРПОУ" />
      <TextField source="IPN" label="ІПН" />
      <ReferenceField
        source="account"
        reference="firms/get_accounts"
        label="Рахунок"
      >
        <TextField source="account" />
      </ReferenceField>
      <ReferenceField
        source="director"
        reference="staff/get_staff"
        label="Директор"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="tax" label="Податкова група" />
      <TextField source="registration" label="Реєстрація" />
      <TextField source="fiscal_number" label="Фіскальний номер" />
      <TextField source="prefix" label="Префікс" />
      <BooleanField source="blocking" />
      <TextField source="code1C" label="Код 1С" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const FirmsShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Перегляд картки">
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="Код" />
          <TextField source="name" label="Назва" />
          <TextField source="full_name" label="Повна назва" />
          <TextField source="adress" label="Адреса" />
          <TextField source="adress_post" label="Поштова адреса" />
          <TextField source="phone" label="Телефон" />
          <EmailField source="email" label="Еmail" />
          <TextField source="EDRPOU" label="Код ЄДРПОУ" />
          <TextField source="IPN" label="ІПН" />
          <ReferenceField
            source="account"
            reference="firms/get_accounts"
            label="Рахунок"
          >
            <TextField source="account" />
          </ReferenceField>
          <ReferenceField
            source="director"
            reference="staff/get_staff"
            label="Директор"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="tax" label="Податкова група" />
          <TextField source="registration" label="Реєстрація" />
          <TextField source="fiscal_number" label="Фіскальний номер" />
          <TextField source="prefix" label="Префікс" />
          <BooleanField source="blocking" />
          <TextField source="code1C" label="Код 1С" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="name" label="Назва" />
    <TextInput source="full_name" label="Повна назва" />
    <TextInput source="adress" label="Адреса" />
    <TextInput source="adress_post" label="Поштова адреса" />
    <TextInput source="phone" label="Телефон" />
    <TextInput source="email" label="Еmail" />
    <TextInput source="EDRPOU" label="Код ЄДРПОУ" />
    <TextInput source="IPN" label="ІПН" />
    <ReferenceInput source="account" reference="firms/get_accounts">
      <AutocompleteInput optionText="account" label="Рахунок" />
    </ReferenceInput>
    <ReferenceInput source="director" reference="staff/get_staff">
      <AutocompleteInput optionText="name" label="Директор" />
    </ReferenceInput>
    <TextInput source="tax" label="Податкова група" />
    <TextInput source="registration" label="Реєстрація" />
    <TextInput source="fiscal_number" label="Фіскальний номер" />
    <TextInput source="prefix" label="Префікс" />
    <BooleanInput source="blocking" />
    <TextInput
      source="code1C"
      slotProps={{
        input: {
          disabled: true,
        },
      }}
    />
  </>
);

export const FirmsEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Редагування картки">
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const FirmsCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
