import React from "react";
import { Typography, Box } from "@mui/material";
import { DateField } from "react-admin";

const DateFieldInline = ({ source, label, emptyText = "-" }) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="caption" sx={{ mr: 1, ml: 1, fontWeight: "bold" }}>
        {label}
      </Typography>
      <DateField source={source} emptyText={emptyText} />
    </Box>
  );
};

export default DateFieldInline;
