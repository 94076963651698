import {
  List,
  TextField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  SelectInput,
  DatagridConfigurable,
  DateField,
  NumberField,
  BooleanField,
  AutocompleteInput,
  Show,
  ArrayField,
  Datagrid,
  SimpleShowLayout,
  FunctionField,
  useRecordContext,
} from "react-admin";
import StandartListAction from "../commonRA/StandartListAction";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import ActionsField from "../commonRA/ActionsField";
import { Box } from "@mui/material";
import ReferenceFieldInLine from "../commonRA/ReferenceFieldInLine";
import TextFieldInline from "../commonRA/TextFieldInline";
import BooleanFieldInline from "../commonRA/BooleanFieldInline";
import DateFieldInline from "../commonRA/DateFieldInline";
import EditOrderByCart from "../../../components/Basket/EditOrderByCart";

const statusChoices = [
  // { id: 1, name: "Чернетка замовлення" },
  // { id: 2, name: "Нове замовлення" },
  // { id: 3, name: "Пропозиція" },
  // { id: 4, name: "Очікується оплата" },
  // { id: 5, name: "Очікується доставка" },
  // { id: 6, name: "Готове до видачі" },
  // { id: 7, name: "Виконане замовлення" },
  // { id: 8, name: "Скасоване замовлення" },
  { id: 1, name: "Чернетка" },
  { id: 2, name: "Нове" },
  { id: 3, name: "Пропозиція" },
  { id: 4, name: "Оплата" },
  { id: 5, name: "Доставка" },
  { id: 6, name: "Готове" },
  { id: 7, name: "Виконане" },
  { id: 8, name: "Скасоване" },
];

const filters = [
  <ReferenceInput
    source="firm"
    label="Фірма"
    reference="firms/get_firms"
    alwaysOn
    key="firms-filter"
  >
    <SelectInput label="Фірма" />
  </ReferenceInput>,

  <ReferenceInput
    source="client"
    label="Клієнт"
    reference="partners/get_partners"
    alwaysOn
    key="client-filter"
  >
    <AutocompleteInput label="Клієнт" />
  </ReferenceInput>,
  <ReferenceInput
    source="manager"
    label="Менеджер"
    reference="staff/get_staff"
    alwaysOn
    key="staff-filter"
  >
    <SelectInput label="Менеджер" />
  </ReferenceInput>,
  <TextInput
    source="q"
    label="Пошук по коментару"
    alwaysOn
    key="search-filter"
  />,
  <SelectInput
    source="status"
    label="Статус"
    choices={statusChoices}
    alwaysOn
  />,
];

const DeliveryField = () => {
  const record = useRecordContext();
  if (!record || !record.delivery_info) return null;
  return (
    <ReferenceField
      source="delivery_info"
      reference="orders/delivery"
      label="Доставка"
      emptyText="-"
    >
      <>Доставка</>
    </ReferenceField>
  );
};

export const OrdersList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={false}
      sx={{
        "& .MuiTypography-root, & .MuiTableCell-root": {
          fontSize: "0.75rem !important",
        },
      }}
    >
      <TextField source="id" label="№" />
      <DateField source="date" label="Дата" />
      <ReferenceField
        source="client"
        reference="partners/get_partners"
        label="Клієнт"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Сума"
        render={(record) =>
          record.price_scheme
            ? `${record.amount} (${record.price_scheme})`
            : `${record.amount}`
        }
      />
      <BooleanField source="confirm" label="Узг." />
      <DateField source="plan_date" label="Очікується" />
      <TextField source="status_display" label="Статус" />
      <ReferenceField
        source="firm"
        reference="firms/get_firms"
        label="Фірма"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="manager"
        reference="staff/get_staff"
        label="Менеджер"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="coment" label="Коментар" />
      <FunctionField label="Доставка" render={() => <DeliveryField />} />
      <ReferenceField
        source="author_id"
        reference="users/get_users"
        label="Автор"
        emptyText="-"
      >
        <FunctionField
          render={(record) => `${record.last_name} ${record.first_name}`}
        />
      </ReferenceField>
      <ReferenceField
        source="blocked_by_user"
        reference="users/get_users"
        label="Редагується"
        emptyText="-"
      >
        <FunctionField
          render={(record) => `${record.last_name} ${record.first_name}`}
        />
      </ReferenceField>
      <FunctionField
        label="Edit"
        render={(record) => <EditOrderByCart record={record} />}
      />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const OrdersShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Перегляд замовлення"
    >
      <Show {...props}>
        <SimpleShowLayout>
          <Box>
            <Box display="flex">
              <TextFieldInline source="id" label="Замовлення №" />
              <DateFieldInline source="date" label="від" />
            </Box>
            <Box display="flex">
              <FunctionField
                render={(record) =>
                  Number(record.warehouse) !== 0 ? (
                    <ReferenceFieldInLine
                      source="warehouse"
                      reference="sources/get_sources"
                      label="На склад:"
                      textSource="name"
                    />
                  ) : null
                }
              />
              <FunctionField
                render={(record) =>
                  Number(record.warehouse) === 0 ? (
                    <Box display="flex">
                      <ReferenceFieldInLine
                        source="client"
                        reference="partners/get_partners"
                        label="Клієнт:"
                        emptyText="RTL"
                        textSource="name"
                      />
                      <ReferenceFieldInLine
                        source="firm"
                        reference="firms/get_firms"
                        label="від фірми"
                        textSource="name"
                      />

                      <TextFieldInline
                        source="price_scheme"
                        label="зі схемою цін"
                        emptyText="RTL"
                      />
                      <BooleanFieldInline source="confirm" label="узгодження" />
                    </Box>
                  ) : null
                }
              />
              <DateFieldInline
                source="plan_date"
                label="поставка планується на"
              />
            </Box>
            <Box display="flex">
              <TextFieldInline source="status_display" label="Статус:" />
              <ReferenceFieldInLine
                source="author_id"
                reference="users/get_users"
                label="Автор:"
                textSource="username"
              />
              <ReferenceFieldInLine
                source="manager"
                reference="staff/get_staff"
                label="Менеджер:"
                textSource="name"
              />
              <FunctionField
                render={() => (
                  <Box sx={{ ml: 2 }}>
                    <DeliveryField />
                  </Box>
                )}
              />
            </Box>
            <Box display="flex">
              <TextFieldInline source="coment" label="Коментар:" />
            </Box>
          </Box>
          <ArrayField source="rows" label="Рядки замовлення">
            <Datagrid>
              <TextField source="row_num" label="№ рядка" />
              <ReferenceField
                source="item"
                reference="catalog/get_products"
                label="Товар"
                emptyText="-"
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="quantity" label="Кількість" />
              <NumberField source="price_in" label="Вхідна ціна" />
              <TextField source="source" label="Код джерела" />
              <NumberField source="price_out" label="Ціна продажу" />
              <DateField source="plan_date" label="Планована дата" />
              <BooleanField source="available" label="Доступність" />
            </Datagrid>
          </ArrayField>
          <Box display="flex">
            <TextFieldInline source="amount" label="Сума:" />
          </Box>
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};
