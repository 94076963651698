import { debounce } from "lodash";
import { catalogNavigate } from "./catalogNavigate";

export const createDebouncedCatalogNavigate = (
  navigate,
  filters,
  perPage,
  sortTypePrice,
  search,
  setPage
) => {
  return debounce((minPrice, maxPrice) => {
    catalogNavigate(navigate, {
      filters: filters,
      perPage: perPage,
      page: 1,
      sort: sortTypePrice,
      search: search,
      minPrice: minPrice,
      maxPrice: maxPrice,
    });
    setPage(1);
  }, 500); // Затримка в 500 мс
};
