import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./Basket.module.css";
import {
  selectBasketClient,
  selectBasketComent,
  selectBasketConfirm,
  selectBasketDelivery,
  selectBasketFirm,
  selectBasketItems,
  selectBasketManager,
  selectBasketOrderID,
  selectBasketPriceScheme,
  selectBasketWarehouse,
} from "../../redux/basketSelectors";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketCount,
  changeBasketItems,
} from "../../redux/basketSlice";
import { saveBasket } from "./saveBasket";

const BasketPriceChange = ({ available, price_im, id }) => {
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user.status);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basketDate = useSelector((state) => state.basket.updateDate);
  const basket = useSelector(selectBasketItems);
  const basketClient = useSelector(selectBasketClient);
  const basketWarehouse = useSelector(selectBasketWarehouse);
  const basketFirm = useSelector(selectBasketFirm);
  const basketManager = useSelector(selectBasketManager);
  const basketConfirm = useSelector(selectBasketConfirm);
  const basketPriceScheme = useSelector(selectBasketPriceScheme);
  const basketPlanDate = useSelector((state) => state.basket.planDate);
  const basketComent = useSelector(selectBasketComent);
  const basketDelivery = useSelector(selectBasketDelivery);
  const [changePriceBtn, setChangePriceBtn] = useState(false);
  const [value, setValue] = useState(price_im);
  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  const priceChange = () => {
    let newBasket = basket.map((elem) => {
      if (elem.id === id) {
        return {
          ...elem,
          price_im: value,
        };
      }
      return elem;
    });

    const amount = newBasket.reduce(
      (total, item) => total + item.price_im * item.quantity,
      0
    );
    const amountIn = newBasket.reduce(
      (total, item) => total + item.price_in * item.quantity,
      0
    );
    const count = newBasket.reduce((total, item) => total + item.quantity, 0);

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      newBasket,
      count,
      amount,
      amountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setValue(e.target.value);
      setChangePriceBtn(!changePriceBtn);
      priceChange();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        buttonRef.current &&
        !inputRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setChangePriceBtn(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!changePriceBtn && (
        <div
          className={css["quantity-input-order"]}
          onClick={() => {
            setChangePriceBtn(!changePriceBtn);
          }}
        >
          {available === true && <div>{price_im}</div>}
          {available === false && <div style={{ color: "red" }}>немає</div>}
        </div>
      )}
      {changePriceBtn && (
        <>
          <input
            className={css["input-price"]}
            id="input"
            name="input"
            type="number"
            value={value}
            autoComplete="off"
            ref={inputRef}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
          <button
            ref={buttonRef}
            onClick={() => {
              setChangePriceBtn(!changePriceBtn);
              priceChange();
            }}
          >
            S
          </button>
        </>
      )}
    </>
  );
};

export default BasketPriceChange;
