import { useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./CategoriesFilters.module.css";
import CategoriesFiltersGroup from "./CategoriesFiltersGroup";
import BannerSC from "../BannerSC/BannerSC";
import CategoriesFiltersPrice from "./CategoriesFiltersPrice";
import { useLocation } from "react-router-dom";

const CategoriesFilters = ({ categoryID }) => {
  const [list, setList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    apiaxios
      .get("/catalog/get_category_filters/" + categoryID)
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categoryID]);

  return (
    <>
      {list.length !== 0 && (
        <>
          <ul className={css["filter-ul-container"]}>
            <CategoriesFiltersPrice />
            {list.map((item) => (
              <li key={item.group.optionID}>
                <CategoriesFiltersGroup
                  name={item.group.name}
                  allFilters={item.filters}
                />
              </li>
            ))}
          </ul>
          {!location.pathname.startsWith("/admin_portal/") && <BannerSC />}
        </>
      )}
    </>
  );
};

export default CategoriesFilters;
