import { useState } from "react";
import {
  List,
  TextField,
  ReferenceField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  BooleanField,
  useNotify,
  useRefresh,
  AutocompleteInput,
  DateField,
  DateInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
];

export const PricesInList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" label="Код" />
      <TextField source="productID" label="ID товару" />
      <TextField source="vendor" label="Постачальник" />
      <TextField source="vendor_code" label="Код постачальника" />
      <TextField source="vendor_category_id" label="Категорія постачальника" />
      <ReferenceField
        source="brand"
        reference="catalog/get_brands_vendors"
        label="Виробник по постачальнику"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brand_catalog"
        reference="catalog/get_brands"
        label="Виробник по каталогу"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="price" label="Ціна" />
      <TextField source="price_bez_pdv" label="Ціна без ПДВ" />
      <TextField source="quantity" label="Кількість" />
      <DateField source="delivery_date" label="На коли" />
      <BooleanField source="available" label="Доступність" />
      <BooleanField source="blocked" label="Забалоковано" />
      <BooleanField source="checked" label="Перевірено" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const PricesInShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Перегляд картки">
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="Код" />
          <TextField source="productID" label="ID товару" />
          <TextField source="vendor" label="Постачальник" />
          <TextField source="vendor_code" label="Код постачальника" />
          <TextField
            source="vendor_category_id"
            label="Категорія постачальника"
          />
          <ReferenceField
            source="brand"
            reference="catalog/get_brands_vendors"
            label="Виробник по постачальнику"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="brand_catalog"
            reference="catalog/get_brands"
            label="Виробник по каталогу"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="price" label="Ціна" />
          <TextField source="price_bez_pdv" label="Ціна без ПДВ" />
          <TextField source="quantity" label="Кількість" />
          <DateField source="delivery_date" label="На коли" />
          <BooleanField source="available" label="Доступність" />
          <BooleanField source="blocked" label="Забалоковано" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="productID" label="ID товару" />
    <TextInput source="vendor" label="Постачальник" />
    <TextInput source="vendor_code" label="Код постачальника" />
    <TextInput source="vendor_category_id" label="Категорія постачальника" />
    <ReferenceInput source="brand" reference="catalog/get_brands_vendors">
      <AutocompleteInput optionText="name" label="Виробник по постачальнику" />
    </ReferenceInput>
    <ReferenceInput source="brand_catalog" reference="catalog/get_brands">
      <AutocompleteInput optionText="name" label="Виробник по каталогу" />
    </ReferenceInput>
    <TextInput source="price" label="Ціна" />
    <TextInput source="price_bez_pdv" label="Ціна без ПДВ" />
    <TextInput source="quantity" label="Кількість" />
    <DateInput source="delivery_date" label="На коли" />
    <BooleanInput source="available" label="Доступність" />
    <BooleanInput source="blocked" label="Забалоковано" />
  </>
);

export const PricesInEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Редагування картки">
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const PricesInCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
