import { useState } from "react";
import {
  List,
  TextField,
  ReferenceField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  EmailField,
  BooleanField,
  useNotify,
  useRefresh,
  ImageField,
  ImageInput,
  AutocompleteInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const validateUser = (values) => {
  const errors = {};
  const staffIDString =
    values.staffID != null ? String(values.staffID).trim() : "";

  if (values.is_staff && staffIDString === "") {
    errors.staffID =
      "Для співробітників обов'язково потрібно вказати співробітника.";
  }
  if (!values.is_staff && staffIDString !== "") {
    errors.staffID = "Для користувачів співробітник не повинен бути вказаний.";
  }

  return errors;
};

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
  <BooleanInput
    source="full_catalog"
    label="Повний каталог"
    key="full_catalog-filter"
  />,
];

export const UsersList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" />
      <ImageField source="image" />
      <TextField source="username" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <EmailField source="email" />
      <TextField source="telephone" />
      <BooleanField source="is_staff" />
      <BooleanField source="full_catalog" />
      <ReferenceField
        source="cart"
        reference="orders/list"
        label="Кошик"
        emptyText="-"
      />
      <ReferenceField
        source="current_order"
        reference="orders/list"
        label="Поточне замовлення"
        emptyText="-"
      />
      <TextField source="favorite" />
      <ReferenceField
        source="client"
        reference="partners/get_partners"
        label="Картка клієнта"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="staffID"
        reference="staff/get_staff"
        label="Співробітник"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="is_verified_email" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const UsersShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Перегляд картки користувача"
    >
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="username" />
          <TextField source="first_name" />
          <TextField source="last_name" />
          <EmailField source="email" />
          <TextField source="telephone" />
          <ImageField source="image" />
          <BooleanField source="is_staff" />
          <BooleanField source="full_catalog" />
          <ReferenceField
            source="cart"
            reference="orders/list"
            label="Кошик"
            emptyText="-"
          />
          <ReferenceField
            source="current_order"
            reference="orders/list"
            label="Поточне замовлення"
            emptyText="-"
          />
          <TextField source="favorite" />
          <ReferenceField
            source="client"
            reference="partners/get_partners"
            label="Картка клієнта"
            emptyText="-"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="staffID"
            reference="staff/get_staff"
            label="Співробітник"
            emptyText="-"
          >
            <TextField source="name" />
          </ReferenceField>
          <BooleanField source="is_verified_email" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="username" />
    <TextInput source="first_name" />
    <TextInput source="last_name" />
    <TextInput source="email" />
    <TextInput source="telephone" />
    <ImageInput source="image" label="Аватар" accept="image/*">
      <ImageField source="src" title="title" />
    </ImageInput>
    <BooleanInput source="is_staff" />
    <BooleanInput source="full_catalog" />
    <ReferenceInput source="cart" reference="orders/list">
      <AutocompleteInput optionText="name" label="Кошик" />
    </ReferenceInput>
    <ReferenceInput source="current_order" reference="orders/list">
      <AutocompleteInput
        optionText="name"
        label="Поточне замовлення"
        emptyText="-"
      />
    </ReferenceInput>
    <TextInput source="favorite" />
    <ReferenceInput source="client" reference="partners/get_partners">
      <AutocompleteInput
        optionText="name"
        label="Картка клієнта"
        emptyText="-"
      />
    </ReferenceInput>
    <ReferenceInput source="staffID" reference="staff/get_staff">
      <AutocompleteInput optionText="name" label="Співробітник" />
    </ReferenceInput>
    <BooleanInput source="is_verified_email" />
  </>
);

export const UsersEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Редагування картки користувача"
    >
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm validate={validateUser} toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const UsersCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Клієнта успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки користувача"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
