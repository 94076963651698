import { DeleteWithConfirmButton, EditButton, WrapperField } from "react-admin";

const ActionsField = () => {
  return (
    <WrapperField
      label="Дії"
      sx={{
        display: "flex",
        alignItems: "center", // Вирівнювання по вертикалі
        gap: 1, // Відстань між кнопками
      }}
    >
      <EditButton
        label=""
        sx={{
          padding: 0,
          margin: 0,
          minWidth: "unset", // Прибираємо мінімальну ширину
        }}
      />
      <DeleteWithConfirmButton
        confirmContent="Видалити картку?"
        confirmColor="warning"
        translateOptions={{ name: "картку" }}
        label=""
        sx={{
          padding: 0,
          margin: 0,
          minWidth: "unset", // Прибираємо мінімальну ширину
        }}
      />
    </WrapperField>
  );
};

export default ActionsField;
