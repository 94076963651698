import css from "./ProductsList.module.css";
import { useContext } from "react";
import userStatusContext from "../../context/userStatusContext";
import { useNavigate } from "react-router-dom";
import { catalogNavigate } from "../../common/catalogNavigate";

const ProductListPagination = ({ listCount }) => {
  const {
    sortTypePrice,
    search,
    filters,
    page,
    setPage,
    perPage,
    minPrice,
    maxPrice,
  } = useContext(userStatusContext);
  const navigate = useNavigate();
  let pagesCount = 0;
  if (listCount % perPage !== 0) {
    pagesCount = Math.floor(listCount / perPage) + 1;
  } else {
    pagesCount = Math.floor(listCount / perPage);
  }
  const pagesCountList = [];
  if (pagesCount <= 9) {
    for (let i = 1; i <= pagesCount; i++) {
      pagesCountList.push({ id: i, text: i });
    }
  } else {
    if (page <= 5) {
      for (let i = 1; i <= 7; i++) {
        pagesCountList.push({ id: i, text: i });
      }
      pagesCountList.push({ id: 8, text: "..." });
      pagesCountList.push({ id: pagesCount, text: pagesCount });
    } else {
      if (pagesCount - page >= 5) {
        pagesCountList.push({ id: 1, text: "1" });
        pagesCountList.push({ id: page - 3, text: "..." });
        pagesCountList.push({ id: page - 2, text: page - 2 });
        pagesCountList.push({ id: page - 1, text: page - 1 });
        pagesCountList.push({ id: page, text: page });
        pagesCountList.push({ id: page + 1, text: page + 1 });
        pagesCountList.push({ id: page + 2, text: page + 2 });
        pagesCountList.push({ id: page + 3, text: "..." });
        pagesCountList.push({ id: pagesCount, text: pagesCount });
      } else {
        pagesCountList.push({ id: 1, text: "1" });
        pagesCountList.push({ id: pagesCount - 7, text: "..." });
        for (let i = pagesCount - 6; i <= pagesCount; i++) {
          pagesCountList.push({ id: i, text: i });
        }
      }
    }
  }

  return (
    <div className={css["product-list-pagination-container"]}>
      {pagesCountList.map((item) => (
        <div
          key={item.id}
          id={item.id}
          className={
            item.id === page
              ? css["product-list-pagination-item-active"]
              : css["product-list-pagination-item"]
          }
          onClick={(e) => {
            setPage(Number(e.currentTarget.id));
            catalogNavigate(navigate, {
              filters: filters,
              perPage: perPage,
              page: Number(e.currentTarget.id),
              sort: sortTypePrice,
              search: search,
              minPrice: minPrice,
              maxPrice: maxPrice,
            });
          }}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default ProductListPagination;
