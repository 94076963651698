import css from "./CategoriesFilters.module.css";
import { useContext } from "react";
import userStatusContext from "../../context/userStatusContext";
import { IconContext } from "react-icons";
import { CgCheck } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { catalogNavigate } from "../../common/catalogNavigate";

const CategoriesFiltersSearch = () => {
  const {
    sortTypePrice,
    search,
    filters,
    setFilters,
    perPage,
    filtersSearchAll,
  } = useContext(userStatusContext);
  const navigate = useNavigate();

  const handleClikFilter = (e) => {
    const newList = [...filters];
    if (newList.includes(e.currentTarget.id)) {
      newList[newList.indexOf(e.currentTarget.id)] = "";
    } else {
      newList.push(e.currentTarget.id);
    }
    const newListAfter = [];
    for (const fil of newList) {
      if (fil) {
        newListAfter.push(fil);
      }
    }
    newListAfter.sort();
    catalogNavigate(navigate, {
      filters: newListAfter,
      perPage: perPage,
      page: 1,
      sort: sortTypePrice,
      search: search,
    });
    setFilters(newListAfter);
  };

  return (
    <>
      {filtersSearchAll.length > 0 && (
        <div className={css["filter-ul-container"]}>
          <ul className={css["filter-search-block-group"]}>
            {filtersSearchAll.map((item) => (
              <li
                key={item.filterID}
                className={css["filter-block-item"]}
                onClick={handleClikFilter}
                id={item.filterID}
              >
                <div className={css["v_menu"]}>
                  <div className={css["v_block"]}>
                    {filters.includes(String(item.filterID)) && (
                      <div>
                        <IconContext.Provider value={{ size: "1em" }}>
                          <div>
                            <CgCheck />
                          </div>
                        </IconContext.Provider>
                      </div>
                    )}
                  </div>
                  <div className={css["filter-name"]} id={item.filterName}>
                    {item.filterName} ({item.countItem})
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CategoriesFiltersSearch;
