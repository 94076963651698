import { useDispatch, useSelector } from "react-redux";
import { changeUserPrice } from "../../redux/userSlice";

const SelectPriceScheme = () => {
  const dispatch = useDispatch();
  const priceScheme = useSelector((state) => state.user.price);
  const priceSchemeList = useSelector((state) => state.user.priceSchemeList);

  return (
    <select
      id="select"
      name="select"
      value={priceScheme ? priceScheme : "RTL"}
      onChange={(e) => {
        if (e.target.value === "RTL") {
          dispatch(changeUserPrice(""));
        } else dispatch(changeUserPrice(e.target.value));
      }}
    >
      {priceSchemeList.map((item) => (
        <option key={item["code"]} value={item["code"]}>
          {item["code"]}
        </option>
      ))}
    </select>
  );
};

export default SelectPriceScheme;
