import { useSelector } from "react-redux";

const BasketPriceScheme = ({ setValue, basketPriceScheme }) => {
  const priceSchemeList = useSelector((state) => state.user.priceSchemeList);

  return (
    <select
      id="select"
      name="select"
      value={basketPriceScheme}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    >
      {priceSchemeList.map((item) => (
        <option key={item["code"]} value={item["code"]}>
          {item["code"]}
        </option>
      ))}
    </select>
  );
};

export default BasketPriceScheme;
