import no_photo from "../../no-photo-api.png";
import css from "./Basket.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { CgCloseO, CgArrowUp, CgArrowDown } from "react-icons/cg";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { useState } from "react";
import SelectFromList from "../control/SelectFromList/SelectFromList";
import CustomInput from "../control/CustomInput/CustomInput";
import BasketPriceChange from "./BasketPriceChange";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketClient,
  changeBasketComent,
  changeBasketConfirm,
  changeBasketCount,
  changeBasketFirm,
  changeBasketItems,
  changeBasketManager,
  changeBasketToNull,
  changeBasketPriceScheme,
  changeBasketDelivery,
  changeBasketOrderID,
  changeBasketPlanDate,
  changeBasketWarehouse,
} from "../../redux/basketSlice";
import {
  selectBasketAmount,
  selectBasketAmountIn,
  selectBasketClient,
  selectBasketComent,
  selectBasketConfirm,
  selectBasketDelivery,
  selectBasketFirm,
  selectBasketItems,
  selectBasketManager,
  selectBasketOrderID,
  selectBasketPriceScheme,
  selectBasketWarehouse,
} from "../../redux/basketSelectors";
import apiaxios from "../../common/apiaxios";
import CheckBool from "../control/CheckBool/CheckBool";
import BasketDelivery from "./BasketDelivery";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { saveBasket } from "./saveBasket";
import BasketPriceScheme from "./BasketPriceScheme";
import BasketRowVendorSelect from "./BasketRowVendorSelect";
// import { AutocompleteInput, ReferenceInput } from "react-admin";

const Basket = ({ modalClose, type }) => {
  const [showPrice, setShowPrice] = useState(
    JSON.parse(localStorage.getItem("showPrice"))
  );
  const [noneItemsError, setNoneItemsError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [deliveryShow, setDeliveryShow] = useState(false);
  const [successMessageShow, setSuccessMessageShow] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const userStatus = useSelector((state) => state.user.status);
  const name = useSelector((state) => state.user.name);
  const surname = useSelector((state) => state.user.surname);
  const email = useSelector((state) => state.user.email);
  const telephone = useSelector((state) => state.user.telephone);
  const defaultClient = useSelector((state) => state.user.defaultClient);
  const defaultWarehouse = useSelector((state) => state.user.defaultWarehouse);
  const defaultWarehouseClient = useSelector(
    (state) => state.user.defaultWarehouseClient
  );
  const defaultFirm = useSelector((state) => state.user.defaultFirm);
  const staffMember = useSelector((state) => state.user.staffMember);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basketDate = useSelector((state) => state.basket.updateDate);
  const basket = useSelector(selectBasketItems);
  const basketCount = useSelector((state) => state.basket.count);
  const basketAmount = useSelector(selectBasketAmount);
  const basketAmountIn = useSelector(selectBasketAmountIn);
  const basketClient = useSelector(selectBasketClient);
  const basketWarehouse = useSelector(selectBasketWarehouse);
  const basketFirm = useSelector(selectBasketFirm);
  const basketManager = useSelector(selectBasketManager);
  const basketConfirm = useSelector(selectBasketConfirm);
  const basketPriceScheme = useSelector(selectBasketPriceScheme);
  const basketPlanDate = useSelector((state) => state.basket.planDate);
  const basketComent = useSelector(selectBasketComent);
  const basketDelivery = useSelector(selectBasketDelivery);

  const deleteItem = (e) => {
    const itemId = Number(e.currentTarget.id);

    const { newBasket, amount, amountIn, count } = basket.reduce(
      (acc, item) => {
        if (item.id !== itemId) {
          acc.newBasket.push(item);
          acc.amount += item.price_im * item.quantity;
          acc.amountIn += item.price_in * item.quantity;
          acc.count += item.quantity;
        }
        return acc;
      },
      { newBasket: [], amount: 0, amountIn: 0, count: 0 }
    );

    const changedPlanDateFull = newBasket.reduce((maxDate, item) => {
      if (item.plan_date) {
        const currentDate = new Date(item.plan_date);
        return currentDate > maxDate ? currentDate : maxDate;
      }
      return maxDate;
    }, new Date(0)); // Початкове значення - мінімальна дата
    const changedPlanDate = changedPlanDateFull.toISOString().split("T")[0];
    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
    dispatch(changeBasketPlanDate(changedPlanDate));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      newBasket,
      count,
      amount,
      amountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      changedPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const quantityChange = (e) => {
    const updatedBasket = basket.map((item) => {
      if (item.id === Number(e.currentTarget.id)) {
        return {
          ...item,
          quantity: Number(e.currentTarget.value),
        };
      }
      return item;
    });

    const { newBasket, amount, amountIn, count } = updatedBasket.reduce(
      (acc, item) => {
        acc.newBasket.push(item);
        acc.amount += item.price_im * item.quantity;
        acc.amountIn += item.price_in * item.quantity;
        acc.count += item.quantity;
        return acc;
      },
      { newBasket: [], amount: 0, amountIn: 0, count: 0 }
    );

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      newBasket,
      count,
      amount,
      amountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const quantityIncrement = (e) => {
    const updatedBasket = basket.map((item) => {
      if (item.id === Number(e.currentTarget.id)) {
        const newQuantity =
          e.currentTarget.name === "+"
            ? item.quantity + 1
            : item.quantity > 1
            ? item.quantity - 1
            : item.quantity;

        return {
          ...item,
          quantity: newQuantity,
        };
      }
      return item;
    });

    const { newBasket, amount, amountIn, count } = updatedBasket.reduce(
      (acc, item) => {
        acc.newBasket.push(item);
        acc.amount += item.price_im * item.quantity;
        acc.amountIn += item.price_in * item.quantity;
        acc.count += item.quantity;
        return acc;
      },
      { newBasket: [], amount: 0, amountIn: 0, count: 0 }
    );

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      newBasket,
      count,
      amount,
      amountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const setBasketClient = (value) => {
    dispatch(changeBasketClient(value));
    apiaxios
      .get("/partners/get_client_by_id?id=" + value["id"])
      .then((res) => {
        dispatch(changeBasketPriceScheme(res.data.price_scheme["name"]));
        updateBasketByPriceScheme(res.data.price_scheme["name"]);
      })
      .catch((err) => {
        console.log(err);
      });
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      value,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const setBasketWarehouse = (value) => {
    dispatch(changeBasketWarehouse(value));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      basketClient,
      value,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const warehouseChange = () => {
    if (basketWarehouse["id"] !== 0) {
      // setWarehouse(false);
      dispatch(changeBasketWarehouse({ id: 0, name: "" }));
      dispatch(changeBasketClient(defaultClient));
      saveBasket(
        userStatus,
        basketOrderID,
        basketDate,
        basket,
        basketCount,
        basketAmount,
        basketAmountIn,
        basketClient,
        { id: 0, name: "" },
        basketFirm,
        basketManager,
        basketConfirm,
        basketPriceScheme,
        basketPlanDate,
        basketComent,
        basketDelivery
      );
    } else {
      dispatch(changeBasketWarehouse(defaultWarehouse));
      dispatch(changeBasketClient(defaultWarehouseClient));
      saveBasket(
        userStatus,
        basketOrderID,
        basketDate,
        basket,
        basketCount,
        basketAmount,
        basketAmountIn,
        defaultWarehouseClient,
        defaultWarehouse,
        basketFirm,
        basketManager,
        basketConfirm,
        basketPriceScheme,
        basketPlanDate,
        basketComent,
        basketDelivery
      );
      // setWarehouse(true);
    }
  };

  const setBasketFirm = (value) => {
    dispatch(changeBasketFirm(value));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      basketClient,
      basketWarehouse,
      value,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const updateBasketByPriceScheme = (value) => {
    apiaxios
      .get("/orders/update_cart_by_price/", {
        params: {
          price_scheme: value,
        },
      })
      .then((res) => {
        dispatch(changeBasketItems(res.data.basket["items"]));
        dispatch(changeBasketAmount(res.data.basket["basketAmount"]));
        saveBasket(
          userStatus,
          basketOrderID,
          basketDate,
          res.data.basket["items"],
          basketCount,
          res.data.basket["basketAmount"],
          basketAmountIn,
          basketClient,
          basketWarehouse,
          basketFirm,
          basketManager,
          basketConfirm,
          value,
          basketPlanDate,
          basketComent,
          basketDelivery
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setPriceScheme = (value) => {
    dispatch(changeBasketPriceScheme(value));
    if (!basketConfirm) {
      updateBasketByPriceScheme(value);
      // apiaxios
      //   .get("/orders/update_cart_by_price/", {
      //     params: {
      //       price_scheme: value,
      //     },
      //   })
      //   .then((res) => {
      //     dispatch(changeBasketItems(res.data.basket["items"]));
      //     dispatch(changeBasketAmount(res.data.basket["basketAmount"]));
      //     saveBasket(
      //       userStatus,
      //       basketOrderID,
      //       basketDate,
      //       res.data.basket["items"],
      //       basketCount,
      //       res.data.basket["basketAmount"],
      //       basketAmountIn,
      //       basketClient,
      //       basketWarehouse,
      //       basketFirm,
      //       basketManager,
      //       basketConfirm,
      //       value,
      //       basketPlanDate,
      //       basketComent,
      //       basketDelivery
      //     );
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else {
      saveBasket(
        userStatus,
        basketOrderID,
        basketDate,
        basket,
        basketCount,
        basketAmount,
        basketAmountIn,
        basketClient,
        basketWarehouse,
        basketFirm,
        basketManager,
        basketConfirm,
        value,
        basketPlanDate,
        basketComent,
        basketDelivery
      );
    }
  };

  const setBasketManager = (value) => {
    dispatch(changeBasketManager(value));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      value,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const setBasketComent = (value) => {
    dispatch(changeBasketComent(value));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      basketPlanDate,
      value,
      basketDelivery
    );
  };

  const setBasketConfirm = (value) => {
    dispatch(changeBasketConfirm(value));
    saveBasket(
      userStatus,
      basketOrderID,
      basketDate,
      basket,
      basketCount,
      basketAmount,
      basketAmountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      value,
      basketPriceScheme,
      basketPlanDate,
      basketComent,
      basketDelivery
    );
  };

  const clearBasket = () => {
    dispatch(changeBasketToNull(null));
    dispatch(changeBasketClient(defaultClient));
    dispatch(changeBasketWarehouse({ id: 0, name: "" }));
    // setWarehouse(false);
    dispatch(changeBasketFirm(defaultFirm));
    dispatch(changeBasketManager(staffMember));
    const clearDelivery = {
      deliveryType: "office",
      firstname: userStatus === "user" ? name : "",
      surname: userStatus === "user" ? surname : "",
      telephone: userStatus === "user" ? telephone : "",
      email: userStatus === "user" ? email : "",
      regionName: "",
      cityName: "",
      pointName: "",
      adressDelivery: "",
    };
    dispatch(changeBasketDelivery(clearDelivery));
    apiaxios
      .get("/orders/unblock_order/", {
        params: {
          basketOrderID: basketOrderID,
        },
      })
      .then(() => {
        dispatch(changeBasketOrderID(null));
      })
      .catch((err) => {
        console.log(err);
      });
    saveBasket(
      userStatus,
      null,
      basketDate,
      [],
      0,
      0,
      0,
      defaultClient,
      { id: 0, name: "" },
      defaultFirm,
      staffMember,
      false,
      "RTL",
      "",
      "",
      clearDelivery
    );
    localStorage.removeItem("basketStorage");
    localStorage.removeItem("orderAmount");
  };

  const saveOrder = async () => {
    if (basket.length === 0) {
      setNoneItemsError(true);
    } else {
      const order = {
        basketOrderID: basketOrderID,
        items: basket,
        amount: basketAmount,
        clientID: basketClient["id"],
        warehouseID: basketWarehouse["id"],
        firmID: basketFirm["id"],
        managerID: basketManager["id"],
        confirm: basketConfirm,
        priceScheme: basketPriceScheme,
        planDate: basketPlanDate,
        coment: basketComent,
        delivery: basketDelivery,
      };
      await apiaxios
        .post(
          "/orders/save_order/",
          order,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then((res) => {
          setNewUser(res.data.new_user);
          setSuccessMessageShow(true);
        });
      if (userStatus === "staff" || userStatus === "super") {
        if (location.pathname === "/admin_portal/orders/list") {
          window.location.reload();
        } else {
          navigate("/admin_portal/orders/list");
        }
      } else if (userStatus === "user") {
        if (location.pathname === "/profile/orders") {
          window.location.reload();
        } else {
          navigate("/profile/orders");
        }
      } else {
        setSuccessMessageShow(true);
      }
      clearBasket();
      modalClose();
    }
  };

  return (
    <Box sx={{ p: 2 }} className={css["container"]}>
      {successMessageShow ? (
        <Box sx={{ textAlign: "center", my: 5 }}>
          <Typography variant="h5">
            Замовлення було успішно створено!
          </Typography>
          <Typography>
            Деталі замовлення та його статус Ви можете побачити в особистому
            кабінеті.
          </Typography>
          {newUser && (
            <Typography>
              Ви автоматично зареєстровані. Дані для входу надіслано Вам на
              пошту. Відновити пароль можна{" "}
              <MuiLink
                component={Link}
                to="/restore_password"
                onClick={modalClose}
              >
                тут
              </MuiLink>
              .
            </Typography>
          )}
          <Box mt={2}>
            {userStatus === "guest" && (
              <MuiLink component={Link} to="/login" onClick={modalClose}>
                Увійти
              </MuiLink>
            )}
            {userStatus === "user" && (
              <MuiLink
                component={Link}
                to="/profile/orders"
                onClick={modalClose}
              >
                Особистий кабінет
              </MuiLink>
            )}
            <MuiLink component={Link} to="/" onClick={modalClose} ml={5}>
              Повернутись до магазину
            </MuiLink>
          </Box>
        </Box>
      ) : (
        <>
          <div className={css["header-container"]}>
            {(userStatus === "staff" || userStatus === "super") && (
              <div className={css["header-title"]}>
                {basketOrderID
                  ? "Замовлення №" + basketOrderID
                  : "Нове замовлення"}
              </div>
            )}
            {userStatus !== "staff" &&
              userStatus !== "super" &&
              !deliveryShow && (
                <div className={css["header-title"]}>Товари у кошику</div>
              )}
            {userStatus !== "staff" &&
              userStatus !== "super" &&
              deliveryShow && (
                <div className={css["header-title"]}>Оформлення замовлення</div>
              )}
            {type !== "order" && (
              <div style={{ display: "flex" }}>
                {(userStatus === "super" || userStatus === "staff") && (
                  <div
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      setShowPrice(!showPrice);
                      localStorage.setItem(
                        "showPrice",
                        JSON.stringify(!showPrice)
                      );
                    }}
                  >
                    <IconContext.Provider value={{ size: "1.5em" }}>
                      {showPrice ? <FaRegEye /> : <FaRegEyeSlash />}
                    </IconContext.Provider>
                  </div>
                )}
                <div className={css["header-close-btn"]} onClick={modalClose}>
                  <svg width="20" height="20">
                    <use xlinkHref="#svg-icon__close" />
                  </svg>
                </div>
              </div>
            )}
          </div>
          {type !== "order" &&
            (userStatus === "staff" || userStatus === "super") && (
              <div>
                <div
                  style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {basketWarehouse["id"] === 0 && (
                    <>
                      <div>
                        <SelectFromList
                          requestPath={"/partners/get_clients/"}
                          label={"Клієнт:"}
                          value={basketClient}
                          setValue={setBasketClient}
                          width="250px"
                          clear={false}
                        />
                      </div>
                      <div style={{ marginRight: "10px" }}>
                        <BasketPriceScheme
                          setValue={setPriceScheme}
                          basketPriceScheme={basketPriceScheme}
                        />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "3px",
                      marginRight: "5px",
                    }}
                  >
                    <input
                      id="warehouse"
                      type="checkbox"
                      checked={basketWarehouse["id"] !== 0}
                      autoComplete="off"
                      onChange={warehouseChange}
                    />
                    <div style={{ marginLeft: "5px" }}>
                      <label htmlFor="warehouse">на склад</label>
                    </div>
                  </div>
                  {basketWarehouse["id"] !== 0 && (
                    <div style={{ marginLeft: "20px" }}>
                      <SelectFromList
                        requestPath={"/sources/get_warehouses/"}
                        label={"Склад:"}
                        value={basketWarehouse}
                        setValue={setBasketWarehouse}
                        width="250px"
                        clear={false}
                      />
                    </div>
                  )}
                  {basketWarehouse["id"] === 0 && (
                    <>
                      <div style={{ marginRight: "10px" }}>
                        <CheckBool
                          label={"узгоджена ціна"}
                          value={basketConfirm}
                          setValue={setBasketConfirm}
                        />
                      </div>
                      <div>
                        {/* {!location.pathname.startsWith("/admin_portal/") && ( */}
                        <SelectFromList
                          requestPath={"/firms/get_firms_list/"}
                          label={"Фірма:"}
                          value={basketFirm}
                          setValue={setBasketFirm}
                          width="150px"
                          clear={false}
                        />
                        {/* )} */}
                        {/* {location.pathname.startsWith("/admin_portal/") && (
                      <ReferenceInput
                        // label="Фірма:"
                        source={basketFirm["id"]}
                        reference="firms/get_firms"
                      >
                        <AutocompleteInput
                          label="Фірма:"
                          // source="id"
                          // optionText="name"
                          debounce={500}
                        />
                      </ReferenceInput>
                    )} */}
                      </div>
                      <div>
                        <SelectFromList
                          requestPath={"/staff/get_stafflist/"}
                          label={"Відповідальний:"}
                          value={basketManager}
                          setValue={setBasketManager}
                          width="200px"
                          clear={false}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setDeliveryShow(!deliveryShow);
                        }}
                      >
                        Доставка
                      </div>
                    </>
                  )}
                </div>
                <div style={{ marginBottom: "10px", fontSize: "12px" }}>
                  <CustomInput
                    value={basketComent}
                    setValue={setBasketComent}
                    label={"Коментар:"}
                    width="100%"
                  />
                </div>
              </div>
            )}
          {(userStatus === "staff" || userStatus === "super") &&
            basket.length !== 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Фото</th>
                    <th>Назва</th>
                    {showPrice && (
                      <>
                        <th>Ven</th>
                        <th>In</th>
                      </>
                    )}
                    {basketWarehouse["id"] === 0 && <th>Ціна</th>}
                    <th>К-ть</th>
                    {showPrice && <th>InSum</th>}
                    {basketWarehouse["id"] === 0 && <th>Сума</th>}
                    <th>Строк</th>
                    <th>X</th>
                  </tr>
                </thead>
                <tbody>
                  {basket.map(
                    (item) =>
                      ((type === "order" && item.available === true) ||
                        type !== "order") && (
                        <tr key={item.id}>
                          <td>
                            <Link
                              className={css["image"]}
                              to={"/product_details/" + item.id}
                              onClick={modalClose}
                            >
                              {item.full_image ? (
                                <img
                                  src={item.full_image}
                                  width="30px"
                                  alt={item.name}
                                />
                              ) : (
                                <img
                                  src={no_photo}
                                  width="30px"
                                  alt={item.name}
                                />
                              )}
                            </Link>
                          </td>
                          <td>
                            <div style={{ textAlign: "left" }}>
                              <Link
                                style={
                                  item.available === true
                                    ? { color: "black" }
                                    : { color: "red" }
                                }
                                to={"/product_details/" + item.id}
                                onClick={modalClose}
                              >
                                {item.name}
                              </Link>
                            </div>
                          </td>
                          {showPrice && (
                            <>
                              <td>
                                {item.available === true && (
                                  <BasketRowVendorSelect
                                    current_vendor={item.vendor}
                                    id={item.id}
                                  />
                                  // <div>{item.vendor}</div>
                                )}
                                {item.available === false && (
                                  <div style={{ color: "red" }}>немає</div>
                                )}
                              </td>
                              <td>
                                {item.available === true && (
                                  <div>{item.price_in}</div>
                                )}
                                {item.available === false && (
                                  <div style={{ color: "red" }}>немає</div>
                                )}
                              </td>
                            </>
                          )}
                          {basketWarehouse["id"] === 0 && (
                            <td>
                              <BasketPriceChange
                                available={item.available}
                                price_im={item.price_im}
                                id={item.id}
                              />
                            </td>
                          )}
                          <td>
                            <div className={css["quantity-block"]}>
                              <input
                                className={css["quantity-input-order"]}
                                id={item.id}
                                value={item.quantity}
                                type="number"
                                onChange={quantityChange}
                              />
                            </div>
                          </td>
                          {showPrice && (
                            <td>
                              {item.available === true && (
                                <div>{item.price_in * item.quantity} ₴</div>
                              )}
                              {item.available === false && (
                                <div style={{ color: "red" }}>немає</div>
                              )}
                            </td>
                          )}
                          {basketWarehouse["id"] === 0 && (
                            <td>
                              {item.available === true && (
                                <div>{item.price_im * item.quantity} ₴</div>
                              )}
                              {item.available === false && (
                                <div style={{ color: "red" }}>немає</div>
                              )}
                            </td>
                          )}
                          <td>
                            {item.plan_date &&
                              new Date(item.plan_date).toLocaleDateString(
                                "uk-UA",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                          </td>
                          <td>
                            <div
                              className={css["delete-x-order"]}
                              id={item.id}
                              onClick={deleteItem}
                            >
                              <IconContext.Provider value={{ size: "1.5em" }}>
                                <div>
                                  <CgCloseO />
                                </div>
                              </IconContext.Provider>
                            </div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <th></th>
                    <th></th>
                    {showPrice && (
                      <>
                        <th></th>
                        <th></th>
                      </>
                    )}
                    {basketWarehouse["id"] === 0 && <th></th>}
                    <th></th>
                    {showPrice && <th>{basketAmountIn} ₴</th>}
                    {basketWarehouse["id"] === 0 && <th>{basketAmount} ₴</th>}
                    <th>
                      {basketPlanDate &&
                        new Date(basketPlanDate).toLocaleDateString("uk-UA", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                    </th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            )}

          {userStatus !== "staff" &&
            userStatus !== "super" &&
            basket.length !== 0 && (
              <>
                {basket.map(
                  (item) =>
                    ((type === "order" && item.available === true) ||
                      type !== "order") && (
                      <div key={item.id} className={css["row"]}>
                        <Link
                          className={css["image"]}
                          to={"/product_details/" + item.id}
                          onClick={modalClose}
                        >
                          {item.full_image ? (
                            <img
                              src={item.full_image}
                              width="50px"
                              alt={item.name}
                            />
                          ) : (
                            <img src={no_photo} width="50px" alt={item.name} />
                          )}
                        </Link>
                        <div className={css["row-without-img-and-x-block"]}>
                          <Link
                            className={
                              item.available === true
                                ? css["name"]
                                : css["name-not-available"]
                            }
                            to={"/product_details/" + item.id}
                            onClick={modalClose}
                          >
                            {item.name}
                          </Link>
                          <div className={css["row-amount-quantity-block"]}>
                            {item.available === true && (
                              <div className={css["amount-row"]}>
                                {item.price_im} ₴
                              </div>
                            )}
                            {item.available === false && (
                              <div className={css["amount-row-not-available"]}>
                                немає
                              </div>
                            )}
                            <div className={css["quantity-block"]}>
                              <button
                                className={css["quantity-button"]}
                                id={item.id}
                                name="-"
                                onClick={quantityIncrement}
                              >
                                <IconContext.Provider value={{ size: "1em" }}>
                                  <div>
                                    <CgArrowDown />
                                  </div>
                                </IconContext.Provider>
                              </button>
                              <input
                                className={css["quantity-input"]}
                                id={item.id}
                                value={item.quantity}
                                onChange={quantityChange}
                              />
                              <button
                                className={css["quantity-button"]}
                                id={item.id}
                                name="+"
                                onClick={quantityIncrement}
                              >
                                <IconContext.Provider value={{ size: "1em" }}>
                                  <div>
                                    <CgArrowUp />
                                  </div>
                                </IconContext.Provider>
                              </button>
                            </div>
                            {item.available === true && (
                              <div className={css["amount-row"]}>
                                {item.price_im * item.quantity} ₴
                              </div>
                            )}
                            {item.available === false && (
                              <div className={css["amount-row-not-available"]}>
                                немає
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={css["delete-x"]}
                          id={item.id}
                          onClick={deleteItem}
                        >
                          <IconContext.Provider value={{ size: "1.5em" }}>
                            <div>
                              <CgCloseO />
                            </div>
                          </IconContext.Provider>
                        </div>
                      </div>
                    )
                )}
              </>
            )}
          {basket.length === 0 && (
            <div className={css["none-items"]}>
              Товарів у кошику ще немає...
            </div>
          )}
          {noneItemsError && (
            <div className={css["none-items-error"]}>
              Неможна оформити порожнє замовлення!
            </div>
          )}
          <div className={css["fotter"]}>
            <div>
              {!deliveryShow && (
                <>
                  {type !== "order" &&
                    userStatus !== "staff" &&
                    userStatus !== "super" && (
                      <>
                        <button
                          className={css["btn"]}
                          onClick={() => {
                            if (basket.length !== 0) {
                              setDeliveryShow(true);
                            } else {
                              setNoneItemsError(true);
                            }
                          }}
                        >
                          Оформити
                        </button>
                        <button className={css["btn"]} onClick={modalClose}>
                          Продовжити
                        </button>
                        <button className={css["btn"]} onClick={clearBasket}>
                          Очистити
                        </button>
                      </>
                    )}
                </>
              )}
            </div>

            {type !== "order" &&
              userStatus !== "staff" &&
              userStatus !== "super" && (
                <div className={css["amount"]}>
                  <span>{basketAmount} ₴</span>
                </div>
              )}
          </div>
          {deliveryShow && (
            <div>
              <BasketDelivery
                saveOrder={saveOrder}
                setDeliveryShow={setDeliveryShow}
                modalClose={modalClose}
              />
            </div>
          )}
          {type !== "order" &&
            (userStatus === "staff" || userStatus === "super") && (
              <>
                <button className={css["btn"]} onClick={saveOrder}>
                  Зберегти
                </button>
                <button className={css["btn"]} onClick={saveOrder}>
                  Замовити
                </button>
                <button className={css["btn"]} onClick={clearBasket}>
                  Очистити
                </button>
              </>
            )}
        </>
      )}
    </Box>
  );
};

export default Basket;
