import { useState } from "react";
import {
  List,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  useNotify,
  useRefresh,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  ArrayField,
  SingleFieldList,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const filters = [
  <TextInput source="q" label="Пошук у назві" alwaysOn key="search-filter" />,
  <TextInput
    source="vendor_search"
    label="Пошук по вендору"
    alwaysOn
    key="vendor-search-filter"
  />,
  <ReferenceInput
    source="brand"
    label="Виробник з нашого каталога"
    reference="catalog/get_brands"
    key="brand-filter"
  />,
];

export const BrandsVendorsList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" />
      <TextField source="name" label="Виробник у каталозі постачальника" />
      <TextField source="vendor" label="Код виробника" />
      <ReferenceField
        source="brand"
        reference="catalog/get_brands"
        label="Виробник з нашого каталога"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ArrayField source="brands_list" label="Виробники з нашого каталога">
        <SingleFieldList>
          <ReferenceField
            source="brand"
            reference="catalog/get_brands"
            emptyText="-"
          >
            <TextField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const BrandsVendorsShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Перегляд картки">
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" label="Виробник у каталозі постачальника" />
          <TextField source="vendor" label="Код виробника" />
          <ReferenceField
            source="brand"
            reference="catalog/get_brands"
            label="Виробник з нашого каталога"
            emptyText="-"
          >
            <TextField source="name" />
          </ReferenceField>
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="name" label="Виробник у каталозі постачальника" />
    <TextInput source="vendor" label="Код виробника" />
    <ReferenceInput source="brand" reference="catalog/get_brands">
      <AutocompleteInput optionText="name" label="Виробник з нашого каталога" />
    </ReferenceInput>
    <ArrayInput source="brands_list" label="Виробники з нашого каталога">
      <SimpleFormIterator>
        <ReferenceInput source="brand" reference="catalog/get_brands">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

export const BrandsVendorsEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Редагування картки">
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const BrandsVendorsCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
