import React from "react";
import { Typography, Box } from "@mui/material";
import { ReferenceField, TextField } from "react-admin";

const ReferenceFieldInLine = ({
  source,
  reference,
  label,
  textSource,
  emptyText = "-",
}) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Typography variant="caption" sx={{ mr: 1, ml: 1, fontWeight: "bold" }}>
        {label}
      </Typography>
      <ReferenceField
        source={source}
        reference={reference}
        label={label}
        emptyText={emptyText}
      >
        <TextField source={textSource} />
      </ReferenceField>
    </Box>
  );
};

export default ReferenceFieldInLine;
