import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = process.env.REACT_APP_DJANGO_API_URL;
const httpClient = (url, options = {}) => {
  const token = localStorage.getItem("access_token");
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter } = params;
    const sortOrder = order === "ASC" ? "" : "-";
    const query = {
      page,
      page_size: perPage,
      ordering: `${sortOrder}${field}`,
      ...filter, // Додаємо фільтри
    };
    const url = `${apiUrl}/${resource}/?${stringify(query)}`;
    const { json } = await httpClient(url);
    // Проходимо по кожному елементу масиву і додаємо повний шлях до `image`
    const results = json.results.map((item) => ({
      ...item,
      image: item.image && !item.image.startsWith("http")
        ? `${process.env.REACT_APP_DJANGO_MEDIA}${item.image}`
        : item.image,
    }));

    return { data: results, total: json.count };
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}/`;
    const { json } = await httpClient(url);
    // Додаємо бекенд перед зображенням
    if (json.image && !json.image.startsWith("http")) {
      json.image = `${process.env.REACT_APP_DJANGO_MEDIA}${json.image}`;
    }

    return { data: json };
  },

  create: async (resource, params) => {
    if (
      resource === "users" &&
      params.data.image &&
      params.data.image.rawFile instanceof File
    ) {
      const formData = new FormData();
      // Додаємо зображення
      formData.append("image", params.data.image.rawFile);
      // Додаємо інші поля форми
      Object.keys(params.data).forEach((key) => {
        if (key !== "image") {
          formData.append(key, params.data[key]);
        }
      });

      // Приклад для дебагу:
      for (let pair of formData.entries()) {
        console.log(pair[0]+ ': ' + pair[1]);
      }

      const url = `${apiUrl}/${resource}/`;
      const { json } = await httpClient(url, {
        method: "POST",
        body: formData,
      });
      return { data: json };
    } else {
      const url = `${apiUrl}/${resource}/`;
      const { json } = await httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      });
      return { data: json };
    }
  },

  update: async (resource, params) => {
    if (
      resource === "users/get_users" &&
      params.data.image &&
      params.data.image.rawFile instanceof File
    ) {
      const formData = new FormData();
      formData.append("image", params.data.image.rawFile);
      Object.keys(params.data).forEach((key) => {
        if (key !== "image") {
          formData.append(key, params.data[key]);
        }
      });
      const url = `${apiUrl}/${resource}/${params.id}/`;
      const token = localStorage.getItem("access_token");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await fetch(url, {
        method: "PATCH",
        body: formData,
        headers,
      });
      const json = await response.json();
      return { data: json };
    } else {
      // Якщо image не містить новий файл, видаляємо його з payload
      const { image, ...dataWithoutImage } = params.data;
      const url = `${apiUrl}/${resource}/${params.id}/`;
      const { json } = await httpClient(url, {
        method: "PUT",
        body: JSON.stringify(dataWithoutImage),
      });
      return { data: json };
    }
  },

  delete: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}/`;
    const { json } = await httpClient(url, { method: 'DELETE' });
    return { data: json };
  },

  getMany: async (resource, params) => {
    const requests = params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}/`)
    );
    const responses = await Promise.all(requests);
    return {
        data: responses.map(({ json }) => ({
            ...json,
            id: json.id,
        })),
    };
  },
};

export default dataProvider;
