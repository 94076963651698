import css from "./Header.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import CountCircle from "../CountCircle/CountCircle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const FavoriteIcon = () => {
  const userStatus = useSelector((state) => state.user.status);
  const favoriteCount = useSelector((state) => state.user.favoriteCount);
  const navigate = useNavigate();
  const location = useLocation();
  let admin = false;
  let size = "30";
  let path = "/profile/favorite";
  if (location.pathname.startsWith("/admin_portal/")) {
    admin = true;
    size = "25";
    path = "/admin_portal/catalog/favorite";
  }

  return (
    <div
      className={admin ? css["header-icon-admin"] : css["header-icon"]}
      onClick={() => {
        if (userStatus !== "guest") {
          navigate(path, { replace: true });
          if (!admin) {
            window.location.reload();
          }
        } else {
          toast.error("Доступно тільки зареєстрованим користувачам!");
        }
      }}
    >
      <svg width={size} height={size}>
        <use xlinkHref="#svg-icon__heart" />
      </svg>
      {!admin && <div className={css["header-icon-label"]}>ОБРАНЕ</div>}
      {favoriteCount > 0 && (
        <div className={css["header-basket_circle"]}>
          <CountCircle count={favoriteCount} />
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default FavoriteIcon;
