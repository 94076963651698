import { useEffect, useState } from "react";
import css from "./Profile.module.css";
import { Circles } from "react-loader-spinner";
import apiaxios from "../../common/apiaxios";
import { useSelector } from "react-redux";

const Statistic = () => {
  const [statistic, setStatistic] = useState();
  const userStatus = useSelector((state) => state.user.status);
  const [circle, setCircle] = useState(true);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_statistic/")
      .then((res) => {
        setStatistic(res.data);
        setCircle(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="main">
      {userStatus === "super" && (
        <div className={css["util-container"]}>
          <div className="page-title">Статистика</div>
          {circle && (
            <div className="spinner">
              <Circles
                height="150"
                width="150"
                color="rgb(50, 130, 250)"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          {!circle && statistic && (
            <>
              <div>
                Всього карток товару: {statistic.products}, з них доступних{" "}
                {statistic.products_on} та недоступних {statistic.products_off}
              </div>
              {statistic.vendor_pricelist.map((item) => (
                <div>
                  Карток товару з асортименту {item.vendor}:{" "}
                  {item["price_count"]}, з них доступних{" "}
                  {item["price_count_on"]}
                </div>
              ))}
              <div>Повністю заповнених карток: {statistic.products_full}</div>
              <div>
                Карток товару з кодами брейн без зображень:{" "}
                {statistic.products_without_image}
              </div>
              <div>
                Карток товару без бренду: {statistic.products_without_brand}
              </div>
              <div>
                Карток товару без роздрібної ціни:{" "}
                {statistic.products_without_price}
              </div>
              <div>
                Карток товару без роздрібної ціни brain - нові картки:{" "}
                {statistic.products_new}
              </div>
              <div>
                Карток товару перенесених до архіву:{" "}
                {statistic.products_archive}
              </div>
              <div>
                Карток товару коду brain - від інших постачальників:{" "}
                {statistic.products_without_code_brain}
              </div>
              <div>
                Всього категорій: {statistic.categories}, з них активних:{" "}
                {statistic.categories_on}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Statistic;
