import { useState } from "react";
import apiaxios from "../../common/apiaxios";
// import css from "./ProductCard.module.css";

const EditPricesChecked = ({ checkedStatus, id, vendor }) => {
  const [checked, setChecked] = useState(checkedStatus);
  const changeChecked = () => {
    apiaxios
      .post(
        "/catalog/set_price_checked/",
        { id: id, vendor: vendor, prevChecked: checked },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setChecked(res.data["checked"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div onClick={changeChecked}>
      {checked === 1 ? "перевірено !!!" : "перевірити"}
    </div>
  );
};

export default EditPricesChecked;
