import { useState } from "react";
import {
  List,
  TextField,
  ReferenceField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  BooleanField,
  useNotify,
  useRefresh,
  AutocompleteInput,
  NumberField,
  NumberInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
];

export const SourcesList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" label="Код" />
      <TextField source="name" label="Назва" />
      <TextField source="code" label="Код" />
      <BooleanField source="warehouse" label="Склад" />
      <ReferenceField
        source="distributor"
        reference="partners/get_partners"
        label="Постачальник"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="delivery_limit" label="Ліміт" />
      <TextField source="discription" label="Опис" />
      <TextField source="update_time" label="Останнє оновлення" />
      <BooleanField source="import_data" label="Імпортувати" />
      <BooleanField source="show_price" label="Показувати ціни" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const SourcesShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Перегляд картки">
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" label="Код" />
          <TextField source="name" label="Назва" />
          <TextField source="code" label="Код" />
          <BooleanField source="warehouse" label="Склад" />
          <ReferenceField
            source="distributor"
            reference="partners/get_partners"
            label="Постачальник"
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="delivery_limit" label="Ліміт" />
          <TextField source="discription" label="Опис" />
          <TextField source="update_time" label="Останнє оновлення" />
          <BooleanField source="import_data" label="Імпортувати" />
          <BooleanField source="show_price" label="Показувати ціни" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => (
  <>
    <TextInput source="name" label="Назва" />
    <TextInput source="code" label="Код" />
    <BooleanInput source="warehouse" label="Склад" />
    <ReferenceInput
      source="distributor"
      reference="partners/get_partners"
      perPage={1000}
    >
      <AutocompleteInput optionText="name" label="Постачальник" />
    </ReferenceInput>
    <NumberInput source="delivery_limit" label="Ліміт" />
    <TextInput source="discription" label="Опис" />
    <TextInput source="update_time" label="Останнє оновлення" />
    <BooleanInput source="import_data" label="Імпортувати" />
    <BooleanInput source="show_price" label="Показувати ціни" />
  </>
);

export const SourcesEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Редагування картки">
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const SourcesCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
