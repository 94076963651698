import { useState } from "react";
import apiaxios from "../../common/apiaxios";

const EditPricesBlocked = ({ blockedStatus, id, vendor }) => {
  const [blocked, setBlocked] = useState(blockedStatus);
  const changeBlocked = () => {
    apiaxios
      .post(
        "/catalog/set_price_blocked/",
        { id: id, vendor: vendor, prevBlocked: blocked },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((res) => {
        setBlocked(res.data["blocked"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div onClick={changeBlocked}>
      {blocked === 1 ? "розблокувати" : "заблокувати"}
    </div>
  );
};

export default EditPricesBlocked;
