import apiaxios from "../../common/apiaxios";
import { Circles } from "react-loader-spinner";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import css from "./ProductDetails.module.css";
import ProductListPath from "../ProductsList/ProductListPath";
import ProductDetailsImages from "./ProductDetailsImages";
import BannerSC from "../BannerSC/BannerSC";
import BuyButtonModal from "../Basket/BuyButtonModal";
import ProductFavoriteCompare from "../ProductFavoriteCompare/ProductFavoriteCompare";
import { useSelector } from "react-redux";

const ProductDetails = () => {
  const [details, setDetails] = useState({});
  const [list_options, setListOptions] = useState([]);
  const userStatus = useSelector((state) => state.user.status);
  const priceScheme = useSelector((state) => state.basket.priceScheme);
  const { id } = useParams();
  const [bigImage, setBigImage] = useState("");
  useEffect(() => {
    apiaxios
      .get("/catalog/get_product_details?id=" + id, {
        params: {
          price_scheme: priceScheme,
        },
      })
      .then((res) => {
        setDetails(res.data);
        if (details.full_image) {
          if (
            details.full_image.startsWith("http://") ||
            details.full_image.startsWith("static/")
          ) {
            setBigImage("");
          } else {
            setBigImage(details.full_image);
          }
        }
        let list_options = [];
        if (res.data["options"]) {
          if (res.data["options"].length) {
            let optionID = "";
            let name = "";
            let value = "";
            for (const item of res.data["options"]) {
              if (item.optionID !== optionID) {
                list_options.push({ id: optionID, name: name, value: value });
                name = item.name;
                value = item.value;
                optionID = item.optionID;
              } else {
                name = item.name;
                value = value + ", " + item.value;
                optionID = item.optionID;
              }
            }
            list_options.shift();
            console.log(list_options);
            setListOptions(list_options);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, details.full_image, priceScheme]);
  useEffect(() => {
    // Після успішного оновлення виконайте прокручування до верху сторінки
    window.scrollTo({ top: 0, behavior: "smooth" }); // Прокручуємо до верху сторінки з анімацією
  }, []);

  const list_img = [];
  if (details.images) {
    for (const item of details.images) {
      list_img.push(item);
    }
  }

  return (
    <>
      {!details.id && (
        <div className="spinner">
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {details.id && (
        <>
          <div className={css["product-container"]}>
            {details.categoryID && (
              <>
                <ProductListPath
                  categoryID={details.categoryID}
                  name={details.name}
                />
              </>
            )}
            <div className={css["product-card-without-path-block"]}>
              <div className={css["product-head"]}>
                <div className={css["product-head-img-info-block"]}>
                  <div className={css["img-block"]}>
                    <ProductDetailsImages
                      path_list={list_img}
                      name={details.name}
                      bigImage={bigImage}
                      setBigImage={setBigImage}
                    />
                  </div>
                  <div className={css["product-head-info"]}>
                    <div>
                      <div className={css["product-head-info-title"]}>
                        {details.name}
                      </div>
                      <div className={css["product-head-info-option"]}>
                        Код: {details.id}
                      </div>
                      <div className={css["product-head-info-option"]}>
                        Артікул: {details.articul}
                      </div>
                    </div>
                    <div className={css["product-prices"]}>
                      {(userStatus === "super" || userStatus === "staff") && (
                        <div className={css["product-buy-btn-price-block"]}>
                          {details.onoff ? (
                            <div className={css["product-buy-btn"]}>
                              <BuyButtonModal
                                id={details.id}
                                price_im={details.user_price}
                                price_max={details.price_im}
                                name={details.name}
                                full_image={details.full_image}
                                vendor={details.prices[0]["vendor"]}
                                price_in={details.prices[0]["price"]}
                              />
                              {details.quantity !== 0 && (
                                <div
                                  style={{
                                    fontSize: "10px",
                                    color: "red",
                                    textAlign: "center",
                                  }}
                                >
                                  на складі {details.quantity}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className={css["not-avalible"]}>
                              Недоступно
                            </div>
                          )}
                          <ProductFavoriteCompare
                            type={"line"}
                            id={id}
                            favoriteStatus={details.favorite}
                            compareStatus={details.compare}
                            starStatus={details.star}
                          />
                        </div>
                      )}
                      {userStatus !== "super" && userStatus !== "staff" && (
                        <div className={css["product-buy-btn-price-block"]}>
                          {details.onoff ? (
                            <div className={css["product-buy-btn"]}>
                              <BuyButtonModal
                                id={details.id}
                                price_im={details.user_price}
                                price_max={details.price_im}
                                name={details.name}
                                full_image={details.full_image}
                              />
                            </div>
                          ) : (
                            <div className={css["not-avalible"]}>
                              Недоступно
                            </div>
                          )}
                          <ProductFavoriteCompare
                            type={"line"}
                            id={id}
                            favoriteStatus={details.favorite}
                            compareStatus={details.compare}
                            starStatus={details.star}
                          />
                        </div>
                      )}
                      {(userStatus === "super" || userStatus === "staff") &&
                        JSON.parse(localStorage.getItem("showPrice")) && (
                          <div className={css["product-our-prices"]}>
                            {details.prices.map((item) => (
                              <div key={item.vendor}>
                                {item["price"] !== 0 &&
                                  item["blocked"] === 0 && (
                                    <div
                                      key={item["vendor"]}
                                      id={item["vendor"]}
                                      className={css["product-price"]}
                                    >
                                      {item["available"] === 1 && (
                                        <>
                                          {item["vendor"] +
                                            ": " +
                                            String(item["price"])}
                                        </>
                                      )}
                                    </div>
                                  )}
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                    <div className={css["product-disr-warranty-block"]}>
                      <div>Короткий опис:</div>
                      <div>{details.brief_description}</div>
                      <div>Гарантія: {details.warranty} місяців</div>
                    </div>
                  </div>
                </div>
                <div className={css["product-options-title"]}>
                  Характеристики:
                </div>
                <div className={css["product-options"]}>
                  {list_options &&
                    list_options.map((item) => (
                      <div
                        key={item.id}
                        className={css["product-options-string"]}
                      >
                        <div className={css["product-options-name"]}>
                          {item.name}
                        </div>
                        <div className={css["product-options-value"]}>
                          {item.value}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className={css["info-banner-block"]}>
                <div>
                  <div className={css["info-block"]}>
                    <div className={css["info-block-title"]}>
                      <div className={css["info-svg"]}>
                        <svg width="25" height="25">
                          <use xlinkHref="#svg-icon__delivery" />
                        </svg>
                      </div>
                      <div>ДОСТАВКА</div>
                    </div>
                    <div className={css["info-block-item"]}>
                      Самовивіз з нашого магазину
                    </div>
                    <div className={css["info-block-item"]}>
                      Доставка Новою поштою
                    </div>
                    <div className={css["info-block-item"]}>
                      <Link
                        to="/delivery"
                        className={css["info-block-item-link"]}
                        target="blank"
                      >
                        Більше інформації
                      </Link>
                    </div>
                  </div>
                  <div className={css["info-block"]}>
                    <div className={css["info-block-title"]}>
                      <div className={css["info-svg"]}>
                        <svg width="25" height="25">
                          <use xlinkHref="#svg-icon__payment" />
                        </svg>
                      </div>
                      <div>ОПЛАТА</div>
                    </div>
                    <div className={css["info-block-item"]}>
                      Готівкою при отриманні у магазині
                    </div>
                    <div className={css["info-block-item"]}>
                      Безготівковий розрахунок без ПДВ/з ПДВ
                    </div>
                    <div className={css["info-block-item"]}>
                      <Link
                        to="/delivery"
                        className={css["info-block-item-link"]}
                        target="blank"
                      >
                        Більше інформації
                      </Link>
                    </div>
                  </div>
                  <div className={css["info-block"]}>
                    <div className={css["info-block-title"]}>
                      <div className={css["info-svg"]}>
                        <svg width="25" height="25">
                          <use xlinkHref="#svg-icon__phone-big" />
                        </svg>
                      </div>
                      <div>ВИНИКЛИ ПИТАННЯ?</div>
                    </div>
                    <div className={css["info-block-item"]}>Телефонуйте:</div>
                    <div className={css["info-block-item"]}>
                      <b>0542 61 91 25</b>
                    </div>
                    <div className={css["info-block-item"]}>
                      <b>066 777 91 25</b>
                    </div>
                    <div className={css["info-block-item"]}>
                      <b>067 777 91 25</b>
                    </div>
                    <div className={css["info-block-item"]}>
                      <Link
                        to="/contacts"
                        className={css["info-block-item-link"]}
                        target="blank"
                      >
                        Більше інформації
                      </Link>
                    </div>
                  </div>
                </div>
                <div>
                  <BannerSC />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetails;
