import { useEffect, useState } from "react";
import css from "./ProductCard.module.css";
import { IconContext } from "react-icons";
import { FaEdit } from "react-icons/fa";
import EditPricesBlocked from "./EditPricesBlocked";
import AddPricesBtn from "./AddPriceBtn";
import SelectCategory from "../Dictionaries/SelectCaregory/SelectCategory";
import apiaxios from "../../common/apiaxios";
import EditPricesChecked from "./EditPricesChecked";

const EditCardBtn = ({
  id,
  name,
  brand = "",
  categoryID,
  prices,
  sale_prices,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [priceList, setPriceList] = useState(prices);
  const [salePriceList] = useState(sale_prices);
  const [categorySelectOpen, setCategorySelectOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    if (selectedCategory) {
      apiaxios
        .post(
          "/catalog/set_product_category/",
          selectedCategory,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedCategory]);

  const modalOpen = (e) => {
    setCategorySelectOpen(true);
    setTitle("Обераємо категорію для: " + name);
    // setSelectedID(e.currentTarget.id);
  };

  const modalClose = () => {
    setCategorySelectOpen(false);
    setTitle("");
    // setSelectedID("");
  };

  const deletePrice = (id, vendor_code) => {
    apiaxios
      .post(
        "/catalog/delete_price/",
        { id: id, vendor_code: vendor_code },
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then(() => {
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={css["btn-buy-container"]}
        onClick={() => {
          setEditOpen(true);
        }}
      >
        <div className={css["btn-buy"]}>
          <IconContext.Provider value={{ size: "1em" }}>
            <FaEdit />
          </IconContext.Provider>
        </div>
      </div>
      {editOpen && (
        <div className={css["modal-edit-prices-container"]}>
          <div className={css["modal-edit-prices-content"]}>
            <div className={css["modal-header-container"]}>
              <div className={css["modal-header-title"]}>
                Картка товару: {name} Бренд: {brand}
              </div>
              <div
                className={css["modal-header-close-btn"]}
                onClick={() => {
                  setEditOpen(false);
                }}
              >
                <svg width="20" height="20">
                  <use xlinkHref="#svg-icon__close" />
                </svg>
              </div>
            </div>
            <div
              className={css["modal-edit-product-category"]}
              onClick={modalOpen}
            >
              Поточна категорія товару: {categoryID ? categoryID : "None"}{" "}
            </div>
            {categorySelectOpen && (
              <SelectCategory
                title={title}
                selectedID={id}
                setSelectedCategory={setSelectedCategory}
                modalClose={modalClose}
              />
            )}
            <AddPricesBtn
              name={name}
              id={id}
              priceList={priceList}
              setPriceList={setPriceList}
            />
            <div className={css["modal-edit-prices-table"]}>
              <div className={css["modal-edit-prices-row-header"]}>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "10%" }}
                >
                  Постачальник
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "10%" }}
                >
                  Код
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "5%" }}
                >
                  Ціна
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "25%" }}
                >
                  Кількість
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "10%" }}
                >
                  Доступність
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "10%" }}
                >
                  Виробник
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "10%" }}
                >
                  На дату
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "25%" }}
                >
                  Блокування, перевірка, видалення
                </div>
              </div>
              {priceList.map((item) => (
                <div
                  key={item["vendor"]}
                  className={css["modal-edit-prices-row"]}
                >
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "10%" }}
                  >
                    {item["vendor"]}
                  </div>
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "10%" }}
                  >
                    {item["vendor_code"]}
                  </div>
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "5%" }}
                  >
                    {item["price"]}
                  </div>
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "25%" }}
                  >
                    {item["quantity"] ? item["quantity"] : "???"}
                  </div>
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "10%" }}
                  >
                    {item["available"] === 1 ? "є" : "немає"}
                  </div>
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "10%" }}
                  >
                    {item["brand"]}
                  </div>
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "10%" }}
                  >
                    {item["delivery_date"]}
                  </div>
                  <div
                    className={css["modal-edit-prices-item-block"]}
                    style={{ width: "11%" }}
                  >
                    <EditPricesBlocked
                      blockedStatus={item["blocked"]}
                      id={id}
                      vendor={item["vendor"]}
                    />
                  </div>
                  <div
                    className={css["modal-edit-prices-item-block"]}
                    style={{ width: "11%" }}
                  >
                    <EditPricesChecked
                      checkedStatus={item["checked"]}
                      id={id}
                      vendor={item["vendor"]}
                    />
                  </div>
                  <div
                    className={css["modal-edit-prices-item-block"]}
                    style={{ width: "3%" }}
                    onClick={() => deletePrice(id, item["vendor_code"])}
                  >
                    Х
                  </div>
                </div>
              ))}
            </div>
            <div style={{ textAlign: "center" }}>Ціни продажу:</div>
            <div className={css["modal-edit-prices-table"]}>
              <div className={css["modal-edit-prices-row-header"]}>
                {/* <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "15%" }}
                >
                  Постачальник
                </div> */}
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "15%" }}
                >
                  Код
                </div>
                {/* <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "50%" }}
                >
                  Назва
                </div> */}
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "15%" }}
                >
                  Ціна
                </div>
                {/* <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "15%" }}
                >
                  Доступність
                </div>
                <div
                  className={css["modal-edit-prices-item"]}
                  style={{ width: "15%" }}
                >
                  Блокування
                </div> */}
              </div>
              {salePriceList.map((item) => (
                <div
                  key={item["code"]}
                  className={css["modal-edit-prices-row"]}
                >
                  {/* <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "15%" }}
                  >
                    {item["vendor"]}
                  </div> */}
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "15%" }}
                  >
                    {item["code"]}
                  </div>
                  {/* <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "50%" }}
                  >
                    {item["name"]}
                  </div> */}
                  <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "15%" }}
                  >
                    {item["price"]}
                  </div>
                  {/* <div
                    className={css["modal-edit-prices-item"]}
                    style={{ width: "15%" }}
                  >
                    {item["available"] === 1 ? "є" : "немає"}
                  </div>
                  <div
                    className={css["modal-edit-prices-item-block"]}
                    style={{ width: "15%" }}
                  >
                    <EditPricesBlocked
                      blockedStatus={item["blocked"]}
                      id={id}
                      vendor={item["vendor"]}
                    />
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditCardBtn;
